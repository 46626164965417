import React, { useState } from "react";
import { addCart, addRemoveWishlist } from "state/slices/beautyMall";
import { useDispatch, useSelector } from "state/store";
import { IMAGE_BASE_URL } from "utils/constants";
import { NewItem } from "utils/utils";
import ColorSelect from "./ColorSelect";
import { ReviewCountText, ReviewStar } from "./RatingStar";
import parse from "html-react-parser";

const Product = ({ item }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.customer);
  const [count, setCount] = useState(1);
  const [productVariants, setProductVariants] = useState(item?.variants);
  const [variant, setVariant] = useState(
    item?.variants[0]?.colors.length > 0
      ? {
          label: item?.variants[0]?.colors[0]?.colorTitle,
          value: item?.variants[0]?.colors[0]?._id,
          color: item?.variants[0]?.colors[0]?.colorCode,
        }
      : {
          label: item?.variants[0]?.size,
          value: item?.variants[0]?._id,
          color: "#FFFFFF",
        }
  );
  const [media, setMedia] = useState(
    item?.variants[0]?.colors.length > 0
      ? item?.variants[0]?.colors[0]?.media
      : item?.variants[0]?.media
  );

  let colors = [];
  let image = "";
  if (Array.isArray(item?.variants)) {
    if (Array.isArray(item?.variants[0]?.colors)) {
      if (item?.variants[0]?.colors?.length > 0) {
        colors = item?.variants[0]?.colors;
        if (Array.isArray(item?.variants[0]?.colors[0]?.media)) {
          image = `${IMAGE_BASE_URL}${item?.variants[0]?.colors[0]?.media[0]?.fileName}`;
        }
      } else {
        image = `${IMAGE_BASE_URL}${item?.variants[0]?.media[0]?.fileName}`;
      }
    }
  }

  let reviewCount = 0;
  let average = 0.0;
  if (Array.isArray(item.reviews)) {
    if (item.reviews.length > 0) {
      reviewCount = item.reviews[0].count;
      average = item.reviews[0].average;
    }
  }

  const onAddToCart = (item) => {
    dispatch(addCart(item));
  };

  const handleAddToCart = () => {
    onAddToCart({
      ...item,
      subTotal: item.discountedPrice * count,
      quantity: count,
      variant: variant,
    });
  };

  function colorClicked(item) {
    setVariant({
      label: item.colorTitle,
      value: item._id,
      color: item.colorCode,
    });
    const colorItem = colors.find((element) => {
      return element._id === item._id;
    });
    setMedia(colorItem.media);
  }

  function updateWishlist() {
    if (token) {
      dispatch(addRemoveWishlist(item, token, media[0]?.fileName));
    } else {
      alert("Please login to save wishlist");
    }
  }

  return (
    <div className="col-6 col-lg-4 mb-4 mb-sm-9">
      <div className="product-item product-st2-item">
        <div className="product-thumb">
          <a className="d-block" href={`/product/${item._id}`}>
            <img src={image} width={370} height={450} alt="1" />
          </a>
          <NewItem createdDate={item.createdAt} />
        </div>
        <div className="product-info">
          <div
            style={{
              fontSize: 14,
              color: "gray",
              marginBottom: 4,
            }}
          >
            {item?.brand[0]?.name}
          </div>
          <h4 className="title">
            <a href={`/product/${item._id}`}>{item.name}</a>
          </h4>
          <div className="product-rating">
            <div className="rating">
              <ReviewStar rating={average} />
            </div>
            <ReviewCountText count={reviewCount} />
          </div>
          <div className="prices">
            <span className="price">৳{item.discountedPrice}</span>
            <span className="price-old">৳{item.regularPrice}</span>
            <span
              style={{
                color: "#ff6565",
                fontSize: 12,
                paddingLeft: 8,
              }}
            >
              (
              {(
                ((item.regularPrice - item.discountedPrice) /
                  item.regularPrice) *
                100
              ).toFixed(2)}
              %)
            </span>
          </div>
          <div className="product-action">
            <button
              type="button"
              className="product-action-btn action-btn-cart"
              data-bs-toggle="modal"
              data-bs-target={`#action-QuickViewModal${item._id}`}
            >
              <span>Add to cart</span>
            </button>
            <button
              type="button"
              className="product-action-btn action-btn-quick-view"
              data-bs-toggle="modal"
              data-bs-target={`#action-QuickViewModal${item._id}`}
            >
              <i className="fa fa-expand" />
            </button>
            <button
              type="button"
              className="product-action-btn action-btn-wishlist"
              data-bs-toggle="modal"
              data-bs-target={token ? `#action-WishlistModal${item._id}` : ""}
              onClick={updateWishlist}
            >
              <i className="fa fa-heart-o" />
            </button>
          </div>
          <div className="product-action-bottom">
            <button
              type="button"
              className="product-action-btn action-btn-quick-view"
              data-bs-toggle="modal"
              data-bs-target={`#action-QuickViewModal${item._id}`}
            >
              <i className="fa fa-expand" />
            </button>
            <button
              type="button"
              className="product-action-btn action-btn-wishlist"
              data-bs-toggle="modal"
              data-bs-target={token ? `#action-WishlistModal${item._id}` : ""}
              onClick={updateWishlist}
            >
              <i className="fa fa-heart-o" />
            </button>
            <button
              type="button"
              className="product-action-btn action-btn-cart"
              data-bs-toggle="modal"
              data-bs-target={`#action-QuickViewModal${item._id}`}
            >
              <span>Add to cart</span>
            </button>
          </div>
        </div>
      </div>

      {/*== Start Product Quick Add Cart Modal ==*/}
      <aside
        className="product-action-modal modal fade"
        id={`action-CartAddModal${item._id}`}
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="product-action-view-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <i className="fa fa-times" />
                </button>
                <div className="modal-action-messages">
                  <i className="fa fa-check-square-o" /> Added to cart
                  successfully!
                </div>
                <div className="modal-action-product">
                  <div className="thumb">
                    <img
                      style={{
                        background: "#DFE3E8",
                        borderRadius: "5px",
                      }}
                      src={
                        media !== undefined
                          ? IMAGE_BASE_URL + media[0]?.fileName
                          : ""
                      }
                      alt="Organic Food Juice"
                      width={466}
                      height={320}
                    />
                  </div>
                  <h4 className="product-name">
                    <a href={`/product/${item._id}`}>{item.name}</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {/*== End Product Quick Add Cart Modal ==*/}

      {/*== Start Product Quick Wishlist Modal ==*/}
      <aside
        className="product-action-modal modal fade"
        id={`action-WishlistModal${item._id}`}
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="product-action-view-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <i className="fa fa-times" />
                </button>
                <div className="modal-action-messages">
                  <i className="fa fa-check-square-o" /> Added to wishlist
                  successfully!
                </div>
                <div className="modal-action-product">
                  <div className="thumb">
                    <img
                      style={{
                        background: "#DFE3E8",
                        borderRadius: "5px",
                      }}
                      src={image}
                      alt="Organic Food Juice"
                      width={466}
                      height={320}
                    />
                  </div>
                  <h4 className="product-name">
                    <a href={`/product/${item._id}`}>{item.name}</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {/*== End Product Quick Wishlist Modal ==*/}

      {/*== Start Product Quick View Modal ==*/}
      <aside
        className="product-cart-view-modal modal fade"
        id={`action-QuickViewModal${item._id}`}
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="product-quick-view-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <span className="fa fa-close" />
                </button>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      {/*== Start Product Thumbnail Area ==*/}
                      <div className="product-single-thumb">
                        <img
                          style={{
                            background: "#DFE3E8",
                            borderRadius: "5px",
                          }}
                          src={
                            media !== undefined
                              ? IMAGE_BASE_URL + media[0]?.fileName
                              : ""
                          }
                          width={544}
                          height={560}
                          alt="shop4"
                        />
                      </div>
                      {/*== End Product Thumbnail Area ==*/}
                    </div>
                    <div className="col-lg-6">
                      {/*== Start Product Info Area ==*/}
                      <div className="product-details-content">
                        <h5 className="product-details-collection">
                          {item?.brand[0]?.name}
                        </h5>
                        <h3 className="product-details-title">{item?.name}</h3>
                        <div className="product-details-review mb-5">
                          <div className="product-review-icon">
                            <ReviewStar rating={average} />
                          </div>
                          <ReviewCountText count={reviewCount} />
                        </div>
                        <p className="mb-6">{parse(item?.summary)}</p>
                        {colors.length > 0 ? (
                          <ColorSelect
                            colors={colors}
                            variant={variant}
                            setVariant={setVariant}
                            setMedia={setMedia}
                          />
                        ) : (
                          productVariants?.map((item) => {
                            return (
                              <div
                                key={item?._id}
                                style={{
                                  backgroundColor:
                                    item?._id === variant?.value
                                      ? "black"
                                      : "transparent",
                                  borderRadius: 20,
                                  display: "inline-block",
                                  width: 100,
                                  height: 40,
                                  borderStyle: "solid",
                                  borderWidth: "1px",
                                  marginLeft: "5px",
                                  marginBottom: 24,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 7,
                                    color:
                                      item?._id === variant?.value
                                        ? "white"
                                        : "black",
                                    fontSize: 14,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setVariant({
                                      label: item.size,
                                      value: item._id,
                                      color: "#FFFFFF",
                                    });
                                    setMedia(item?.media);
                                  }}
                                >
                                  {item?.size} {item?.unit[0]?.name}
                                </div>
                              </div>
                            );
                          })
                        )}
                        {colors.map((item) => {
                          return (
                            <div
                              style={{
                                backgroundColor:
                                  item._id === variant?.value
                                    ? "#000000"
                                    : "transparent",
                                width: 50,
                                height: 50,
                                borderRadius: "50%",
                                display: "inline-block",
                                marginTop: 20,
                                marginLeft: 5,
                              }}
                            >
                              <div
                                style={{
                                  width: 45,
                                  height: 45,
                                  backgroundColor: "white",
                                  margin: 2.5,
                                  borderRadius: "50%",
                                  padding: 2.5,
                                }}
                              >
                                <div
                                  onClick={() => {
                                    colorClicked(item);
                                  }}
                                  style={{
                                    backgroundColor: item.colorCode,
                                    width: 40,
                                    height: 40,
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="product-details-pro-qty">
                          <div className="pro-qty">
                            <input
                              type="text"
                              title="Quantity"
                              defaultValue={1}
                              value={count}
                            />
                            <div
                              className="dec qty-btn"
                              onClick={() => setCount(Math.max(count - 1, 1))}
                            >
                              -
                            </div>
                            <div
                              className="inc qty-btn"
                              onClick={() => setCount(count + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                        <div className="product-details-action">
                          <h4 className="price">৳{item.discountedPrice}</h4>
                          <div className="product-details-cart-wishlist">
                            <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              data-bs-target={`#action-CartAddModal${item._id}`}
                              onClick={handleAddToCart}
                            >
                              Add to cart
                            </button>
                          </div>
                        </div>
                      </div>
                      {/*== End Product Info Area ==*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {/*== End Product Quick View Modal ==*/}
    </div>
  );
};

export default Product;
