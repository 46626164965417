import ProductItem from "components/ProductItem";
import React, { useEffect } from "react";
import { getNewProducts } from "state/slices/beautyMall";
import { useDispatch, useSelector } from "state/store";

const BestSellers = () => {
  const dispatch = useDispatch();
  const { newProducts } = useSelector((state) => state.beautyMall);

  useEffect(() => {
    const params = {
      page: 1,
      limit: 30,
      searchQuery: "",
    };

    dispatch(getNewProducts(params));
  }, [dispatch]);

  return (
    <>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <h2 className="page-header-title">Best Sellers</h2>
              </div>
            </div>
            <div className="col-md-7">
              <h5 className="showing-pagination-results mt-5 mt-md-9 text-center text-md-end">
                {/* Showing {products.length} Results */}
                Showing {newProducts?.length} Results
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Product Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="row mb-n4 mb-sm-n10 g-3 g-sm-6">
            {newProducts.map((item) => {
              return <ProductItem key={item._id} item={item} />;
            })}
          </div>
        </div>
      </section>
      {/*== End Product Area Wrapper ==*/}
    </>
  );
};

export default BestSellers;
