import DiscountBanner from "components/DiscountBanner";
import ProductItem from "components/ProductItem";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  getCategories,
  getProducts,
  getProductsSuccess,
} from "state/slices/beautyMall";
import { useDispatch, useSelector } from "state/store";

const AllProducts = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.beautyMall);
  const { products } = useSelector((state) => state.beautyMall);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    const params = {
      page: page,
      limit: 21,
      searchQuery: "",
    };
    dispatch(getCategories());
    dispatch(getProducts(params));
  }, [dispatch, page]);

  const reloadProduct = (index) => {
    dispatch(getProductsSuccess(categories[index]?.products));
  };

  const refreshProduct = () => {
    const params = {
      page: page,
      limit: 21,
      searchQuery: "",
    };
    dispatch(getProducts(params));
  };

  const handleSearch = async () => {
    const params = {
      page: page,
      limit: 21,
      searchQuery: search,
    };
    dispatch(getProducts(params));
  };

  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item">
                    <a className="text-dark" href="index.html">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active text-dark"
                    aria-current="page"
                  >
                    Products
                  </li>
                </ol>
                <h2 className="page-header-title">All Products</h2>
              </div>
            </div>
            <div className="col-md-7">
              <h5 className="showing-pagination-results mt-5 mt-md-9 text-center text-md-end">
                Showing {products.length} Results
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Product Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="row justify-content-between flex-xl-row-reverse">
            <div className="col-xl-9">
              <div className="row g-3 g-sm-6">
                {products.map((item) => {
                  return <ProductItem key={item._id} item={item} />;
                })}
                <div className="col-12">
                  <ul className="pagination justify-content-center me-auto ms-auto mt-5 mb-10">
                    <li className="page-item">
                      <button
                        className="page-link previous"
                        aria-label="Previous"
                        onClick={() => {
                          if (page > 1) {
                            setPage((prevValue) => prevValue - 1);
                          }
                        }}
                      >
                        <span
                          className="fa fa-chevron-left"
                          aria-hidden="true"
                        />
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => {
                          setPage((prevValue) => prevValue);
                        }}
                      >
                        {page}
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => {
                          setPage((prevValue) => prevValue + 1);
                        }}
                      >
                        {page + 1}
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => {
                          setPage((prevValue) => prevValue + 2);
                        }}
                      >
                        {page + 2}
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link">....</button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link next"
                        onClick={() => {
                          setPage((prevValue) => prevValue + 1);
                        }}
                        aria-label="Next"
                      >
                        <span
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="product-sidebar-widget">
                <div className="product-widget-search">
                  <form action="#">
                    <input
                      type="search"
                      value={search}
                      placeholder="Search Here"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button type="submit" onClick={handleSearch}>
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>
                {/* <div
                  style={{
                    zIndex: 10,
                    backgroundColor: "#b8b1b1",
                    height: "300px",
                    position: "absolute",
                    width: "270px",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  YOHO
                </div> */}
                <div className="product-widget">
                  <h4 className="product-widget-title">Price Filter</h4>
                  <div className="product-widget-range-slider">
                    <div className="slider-range" id="slider-range" />
                    <div className="slider-labels">
                      <span id="slider-range-value1" />
                      <span>—</span>
                      <span id="slider-range-value2" />
                    </div>
                  </div>
                </div>
                <div className="product-widget">
                  <h4 className="product-widget-title">Categories</h4>
                  <ul className="product-widget-category">
                    <li>
                      <a
                        href
                        onClick={() => {
                          refreshProduct();
                        }}
                      >
                        <span>All</span>
                      </a>
                    </li>
                    {categories.map((category, index) => {
                      return (
                        <li key={index}>
                          <a
                            href
                            onClick={() => {
                              reloadProduct(index);
                            }}
                          >
                            {category?.name}
                            <span>({category?.products?.length})</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="product-widget mb-0">
                  <h4 className="product-widget-title">Popular Tags</h4>
                  <ul className="product-widget-tags">
                    <li>
                      <a href="blog.html">Beauty</a>
                    </li>
                    <li>
                      <a href="blog.html">MakeupArtist</a>
                    </li>
                    <li>
                      <a href="blog.html">Makeup</a>
                    </li>
                    <li>
                      <a href="blog.html">Hair</a>
                    </li>
                    <li>
                      <a href="blog.html">Nails</a>
                    </li>
                    <li>
                      <a href="blog.html">Hairstyle</a>
                    </li>
                    <li>
                      <a href="blog.html">Skincare</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Product Area Wrapper ==*/}

      {/* Disocunt Banner */}
      <section class="section-space pt-0">
        <DiscountBanner />
      </section>
    </div>
  );
};

export default AllProducts;
