import React from "react";

const Terms = () => {
  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item">
                    <a className="text-dark" href="/home">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active text-dark"
                    aria-current="page"
                  >
                    Terms & Conditions
                  </li>
                </ol>
                <h2 className="page-header-title">Terms & Conditions</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Account Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <p>
            The Beauty Mall welcomes you to the tbm.com.bd website (the "Site").
            The Beauty Mall, its affiliates, subsidiaries, or designees provide
            their services to you subject to the following conditions. If you
            visit or shop at the website, you accept these conditions. Please
            read them carefully. In addition, when you use any current or future
            Site service or visit or purchase from any business affiliated with
            the website, whether or not included in the website, you also will
            be subject to the guidelines and conditions applicable to such
            Service or business.
          </p>
          <p>
            When you visit the website or email us, you communicate with us
            electronically. You consent to receive communications from us
            electronically. We will share with you by email or posting notices
            on this website. You agree that all agreements, notices,
            disclosures, and other communications that we provide to you
            electronically satisfy any legal requirement that such
            communications be in writing.
          </p>
          <p>
            The Beauty Mall maintains the website for personal enjoyment,
            information, education, and shopping convenience. You are encouraged
            to use and enjoy the website, and with your use of the website, you
            agree to these Terms of Service set forth below and the disclaimers
            and terms and conditions stated on the website ("Terms of Service").
          </p>
          <p>
            You may only download material displayed on the website for
            non-commercial, personal use provided you also retain all copyright,
            trademark, and other proprietary notices contained in the material,
            do not modify or alter the material and do not copy or post the
            material on any network computer or broadcast the material in any
            media. It is strictly prohibited to modify, transmit, distribute,
            reuse, repost, "frame," or use the website's content, including the
            art, text, images, audio, and video, for public or commercial
            purposes without an authorized representative of The Beauty Mall. It
            is strictly prohibited to download photos of the products for sale
            on this website. Tampering with the website, misrepresenting the
            identity of a user, using buying agents, or conducting fraudulent
            activities on the website is prohibited.
          </p>
          <p>
            By agreeing to these Terms of Service, you represent that you are at
            least the age of majority in your state or province of residence or
            that you are the age of majority in your state or province of
            residence, and you have given us your consent to allow any of your
            minor dependents to use this website.
          </p>
          <p>
            You may not use our products for any illegal or unauthorized
            purpose, nor may you, in the use of the Service, violate any laws in
            your jurisdiction (including but not limited to copyright laws). You
            must not transmit any worms or viruses or any code of a destructive
            nature. A breach or violation of any of the Terms will result in an
            immediate termination of your Services.
          </p>
          <p>
            We reserve the right to refuse Service to anyone for any reason.
          </p>
          <p>
            You understand that your content (not including credit card
            information) may be transferred unencrypted and involve (a)
            transmissions over various networks and (b) changes to conform and
            adapt to the technical requirements of connecting networks or
            devices. Credit card information is always encrypted during transfer
            over networks.
          </p>
          <p>
            You agree not to reproduce, duplicate, copy, sell, resell, or
            exploit any portion of the Service, use of the Service, or access to
            the Service or any contact on the website through which the Service
            is provided without express written permission by us.
          </p>
          <p>
            The headings in this agreement are included for convenience only and
            will not limit or otherwise affect these Terms.
          </p>
          <p>
            We are only responsible if the information made available on this
            site is accurate, complete, or current. The material on this site is
            provided for general information only. It should only be relied upon
            or used as the sole basis for making decisions with consulting
            primary, more accurate, complete, and timely sources of information.
            Any reliance on the material on this site is at your own risk.
          </p>
          <p>
            This site may contain certain historical information. Historical
            information, necessarily, is not current and is provided for your
            reference only. We reserve the right to modify the contents of this
            site at any time, but we have no obligation to update any
            information on our site. You agree that it is your responsibility to
            monitor changes to our site.
          </p>
          <p>Prices for our products are subject to change without notice.</p>
          <p>
            We reserve the right at any time to modify or discontinue the
            Service (or any part or content thereof) without notice at any time.
          </p>
          <p>
            We shall not be liable to you or to any third party for any
            modification, price change, suspension, or discontinuance of the
            Service.
          </p>
          <p>
            Certain products or services may have limited quantities and are
            subject to return or exchange only according to our Return Policy.
          </p>
          <p>
            We have made every effort to display the colors and images of our
            products that appear at the store as accurately as possible. We
            cannot guarantee that your computer monitor's display of any color
            will be accurate.
          </p>
          <p>
            We reserve the right but are not obligated to limit the sales of our
            products or Services to any person, geographic region, or
            jurisdiction. We may exercise this right on a case-by-case basis. We
            reserve the right to limit the quantities of any products or
            services we offer. All descriptions of products or product pricing
            are subject to change at any time without notice, at the sole
            discretion of us. We reserve the right to discontinue any product at
            any time. Any offer for any product or service made on this site is
            void where prohibited.
          </p>
          <p>
            We do not warrant that the quality of any products, services,
            information, or other material purchased or obtained by you will
            meet your expectations or that any errors in the Service will be
            corrected.
          </p>
          <p>
            We reserve the right to refuse any order you place with us. In our
            sole discretion, we may limit or cancel quantities purchased per
            person, household, or order. These restrictions may include orders
            placed by or under the same customer account or credit card and
            orders using the same billing and shipping address. If we change or
            cancel an order, we may attempt to notify you by contacting the
            email and billing address/phone number provided when the order was
            made. We reserve the right to limit or prohibit orders that, in our
            sole judgment, appear to be placed by dealers, resellers, or
            distributors.
          </p>
          <p>
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases at our store. You agree to
            promptly update your account and other information, including your
            email address, credit card numbers, and expiration dates, so we can
            complete your transactions and contact you as needed.
          </p>
          <p>For more detail, please review our Returns Policy.</p>
          <p>
            We may provide you access to third-party tools we neither monitor
            nor have any control or input. You acknowledge and agree that we
            provide access to such tools" as is" and "as available" without any
            warranties, representations, or conditions and without any
            endorsement. We shall have no liability arising from or relating to
            your use of optional third-party tools.
          </p>
          <p>
            Any use by you of optional tools offered through the site is
            entirely at your own risk and discretion. You should ensure that you
            are familiar with and approve of the terms on which tools are
            provided by the relevant third-party provider(s).
          </p>
          <p>
            In the future, we may also offer new services and features through
            the website (including releasing new tools and resources). Such new
            features and services shall also be subject to these Terms of
            Service.
          </p>
          <p>
            Certain content, products, and services available via our Service
            may include third-party materials.
          </p>
          <p>
            Third-party links on this site may direct you to third-party
            websites that are not affiliated with us. We are not responsible for
            examining or evaluating the content or accuracy, and we do not
            warrant it. We will not have any liability or responsibility for any
            third-party materials or websites or any other materials, products,
            or services of third parties.
          </p>
          <p>
            We are not liable for any harm or damages related to the purchase or
            use of goods, services, resources, content, or any other
            transactions connected with any third-party websites. Please review
            the third-party's policies and practices carefully and ensure you
            understand them before engaging in any transaction. Complaints,
            claims, concerns, or questions regarding third-party products should
            be directed to the third party.
          </p>
          <p>
            If, at our request, you send certain specific submissions (for
            example, contest entries) or without a recommendation from us, you
            send creative ideas, suggestions, proposals, plans, or other
            materials, whether online, by email, by postal mail, or otherwise
            (collectively, 'comments'), you agree that we may, at any time,
            without restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium any comments that you forward to us. We
            are and shall be under no obligation:
          </p>
          <p>To maintain any comments in confidence.</p>
          <p>To pay compensation for any comments.</p>
          <p>To respond to any comments.</p>
          <p>
            We may, but have no obligation to, monitor, edit or remove content
            that we determine in our sole discretion are unlawful, offensive,
            threatening, libelous, defamatory, pornographic, obscene, or
            otherwise objectionable or violates any party's intellectual
            property or these Terms of Service.
          </p>
          <p>
            You agree that your comments will not violate any third party's
            rights, including copyright, trademark, privacy, personality, or
            other personal or proprietary right. You further agree that your
            comments will not contain libelous or otherwise unlawful, abusive,
            or obscene material or contain any computer virus or other malware
            that could affect the operation of the Service or any related
            website. You may not use a false email address, pretend to be
            someone other than yourself, or otherwise mislead us or third
            parties as to the origin of any comments. You are solely responsible
            for any comments you make and their accuracy. We take no
            responsibility and assume no liability for any comments you or any
            third-party posted.
          </p>
          <p>
            Our Privacy Policy governs your submission of personal information
            through the store. Occasionally there may be information on our site
            or in the Service that contains typographical errors, inaccuracies,
            or omissions that may relate to product descriptions, pricing,
            promotions, offers, product shipping charges, transit times, and
            availability. We reserve the right to correct any errors,
            inaccuracies, or omissions and to change or update information or
            cancel orders if any information in the Service or on any related
            website is inaccurate at any time without prior notice (including
            after you have submitted your order).
          </p>
          <p>
            We undertake no obligation to update, amend or clarify information
            in the Service or on any related website, including, without
            limitation, pricing information, except as required by law. No
            specified update or refresh date applied in the Service or on any
            related website should be taken to indicate that all information in
            the Service or related website has been modified or updated.
          </p>
          <p>
            In addition to other prohibitions, as set forth in terms of Service,
            you are prohibited from using the site or its content:
          </p>
          <p>For any unlawful purpose.</p>
          <p>
            To solicit others to perform or participate in any unlawful acts.
          </p>
          <p>
            To violate international, federal, provincial, or state regulations,
            rules, laws, or local ordinances. To infringe upon or violate our
            intellectual property rights or the intellectual property rights of
            others.
          </p>
          <p>
            To harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability. To
            submit false or misleading information.
          </p>
          <p>
            To upload or transmit viruses or any other malicious code that will
            or may be used in any way that will affect the functionality or
            operation of the Service or any related website, other websites, or
            the Internet.
          </p>
          <p>To collect or track the personal information of others.</p>
          <p>To spam, phish, pharm, pretext, spider, crawl, or scrape.</p>
          <p>For any obscene or immoral purpose.</p>
          <p>
            To interfere with or circumvent the security features of the Service
            or any related website, other websites, or the Internet.
          </p>
          <p>
            We reserve the right to terminate your use of the Service or any
            related website for violating any prohibited uses.
          </p>
          <p>
            We do not guarantee, represent or warrant that your use of our
            Service will be uninterrupted, timely, secure, or error-free.
          </p>
          <p>
            We do not warrant that the results that may be obtained from the use
            of the Service will be accurate or reliable.
          </p>
          <p>
            You agree that from time to time, we may remove the Service for
            indefinite periods or cancel the Service at any time without notice
            to you.
          </p>
          <p>
            You expressly agree that your use of, or inability to use, the
            Service is at your sole risk. The Service and all products and
            services delivered to you through the Service are (except as
            expressly stated by us) provided 'as is' and 'as available' for your
            use, without any representation, warranties, or conditions of any
            kind, either express or implied, including all implied warranties or
            conditions of merchantability, merchantable quality, fitness for a
            particular purpose, durability, title, and non-infringement.
          </p>
          <p>
            In no case shall The Beauty Mall, our directors, officers,
            employees, affiliates, agents, contractors, interns, suppliers,
            service providers, or licensors be liable for any injury, loss,
            claim, or any direct, indirect, incidental, punitive, special, or
            consequential damages of any kind, including, without limitation
            lost profits, lost revenue, lost savings, loss of data, replacement
            costs, or any similar damages, whether based in contract, tort
            (including negligence), strict liability or otherwise, arising from
            your use of any of the Service or any products procured using the
            Service, or for any other claim related in any way to your use of
            the Service or any product, including, but not limited to any errors
            or omissions in any content, or any loss or damage of any kind
            incurred as a result of the use of the Service or any content (or
            product) posted, transmitted, or otherwise made available via the
            Service, even if advised of their possibility. Because some states
            or jurisdictions do not allow the exclusion or the limitation of
            liability for consequential or incidental damages, in such states or
            jurisdictions, our liability shall be limited to the maximum extent
            permitted by law.
          </p>
          <p>
            You agree to indemnify, defend and hold harmless The Beauty Mall and
            our parent, subsidiaries, affiliates, partners, officers, directors,
            agents, contractors, licensors, service providers, subcontractors,
            suppliers, interns, and employees harmless from any claim or demand,
            including reasonable attorneys' fees, made by any third party due to
            or arising out of your breach of these Terms of Service or the
            documents they incorporate by reference, or your violation of any
            law or the rights of a third party.
          </p>
          <p>
            Suppose any provision of these Terms of Service is deemed unlawful,
            void, or unenforceable. In that case, such provision shall
            nonetheless be enforceable to the fullest extent permitted by
            applicable law. The unenforceable portion shall be deemed to be
            severed from these Terms of Service; such determination shall not
            affect the validity and enforceability of any other remaining
            provisions.
          </p>
          <p>
            The obligations and liabilities of the parties incurred before the
            termination date shall survive the termination of this agreement for
            all purposes.
          </p>
          <p>
            These Terms of Service are practical unless and until terminated by
            you or us. You may terminate these Terms of Service at any time by
            notifying us that you no longer wish to use our services or when you
            cease using our site.
          </p>
          <p>
            If, in our sole judgment, you fail, or we suspect that you have been
            unable, to comply with any term or provision of these Terms of
            Service, we also may terminate this agreement at any time without
            notice, and you will remain liable for all amounts due up to and
            including the date of termination; and accordingly, may deny you
            access to our Services (or any part thereof). The failure of us to
            exercise or enforce any right or provision of these Terms of Service
            shall not constitute a waiver of such right or provision.
          </p>
          <p>
            These Terms of Service and any policies or operating rules posted by
            us on this site or with respect to The Service constitute the entire
            agreement and understanding between you and us and govern your use
            of the Service, superseding any prior or contemporaneous agreements,
            communications, and proposals, whether oral or written, between you
            and us (including, but not limited to, any previous versions of the
            Terms of Service).
          </p>
          <p>
            Any ambiguities in interpreting these Terms of Service shall not be
            construed against the drafting party.
          </p>
          <p>
            These Terms of Service and any separate agreements whereby we
            provide you Services shall be governed by and construed under the
            laws.
          </p>
          <p>
            You can review the most current version of the Terms of Service at
            any time on this page. At our sole discretion, we reserve the right
            to update, change or replace any part of these Terms of Service by
            posting updates and changes to our website. It is your
            responsibility to check our website periodically for changes. Your
            continued use of or access to our website or the Service following
            the posting of any changes to these Terms of Service constitutes
            acceptance of those changes.
          </p>
          <p>
            Questions about the Terms of Service should be sent to us at
            info@tbm.com.bd
          </p>
        </div>
      </section>
      {/*== End Account Area Wrapper ==*/}
    </div>
  );
};

export default Terms;
