export const NewItem = ({ createdDate }) => {
  const currentDate = new Date();
  const date = new Date(createdDate);

  const milliSeconds = currentDate - date;
  let days = Math.floor(milliSeconds / (86400 * 1000));
  if (days < 8) {
    return <span className="flag-new">new</span>;
  }
  return null;
};

export const FormattedDate = ({ createdDate }) => {
  const date = new Date(createdDate);
  const month = date.toLocaleString("default", { month: "short" });
  var outputDate = month + " " + date.getDate() + ", " + date.getFullYear();
  return <td>{outputDate}</td>;
};

export function formatDate(createdDate) {
  const date = new Date(createdDate);
  const month = date.toLocaleString("default", { month: "short" });
  var outputDate = month + " " + date.getDate() + ", " + date.getFullYear();
  return outputDate;
}
