import React, { useState } from 'react'
import { BASE_URL } from 'utils/constants';
import axios from "utils/axios";
import { useDispatch } from 'state/store';
import { setLogin } from 'state/slices/customer';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [registerPhone, setRegisterPhone] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  const login = async(values) => {
    try {
      const options = {
        method: "POST",
        url: BASE_URL + "/auth/customerLogin",
        data: values,
      };
      const response = await axios.request(options);
      if (response.data.status) {
        dispatch(setLogin(response.data));
        navigate("/customer");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const register = async(values) => {
    try {
      const options = {
        method: "POST",
        url: BASE_URL + "/auth/customerRegister",
        data: values,
      };
      const response = await axios.request(options);
      if (response.data.status) {
        dispatch(setLogin(response.data));
        navigate("/customer");
      } else {
        alert(response.data.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const values = {
      phone: phone,
      password: password,
    }

    await login(values);
  };

  const handleRegister = async () => {
    const values = {
      phone: registerPhone,
      password: registerPassword,
    }

    await register(values);
  };

  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section 
      className="page-header-area pt-10 pb-9" 
      style={{
        backgroundColor: "#FFF3DA",
        }}>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item"><a className="text-dark" href="/home">Home</a></li>
                  <li className="breadcrumb-item active text-dark" aria-current="page">Account</li>
                </ol>
                <h2 className="page-header-title">Account</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Account Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="row mb-n8">
            <div className="col-lg-6 mb-8">
              {/*== Start Login Area Wrapper ==*/}
              <div className="my-account-item-wrap">
                <h3 className="title">Login</h3>
                <div className="my-account-form">
                  <form action="#" method="post">
                    <div className="form-group mb-6">
                      <label htmlFor="login_username">Phone Number <sup>*</sup></label>
                      <input 
                      type="phone" 
                      value={phone} 
                      onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label htmlFor="login_pwsd">Password <sup>*</sup></label>
                      <input 
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group d-flex align-items-center mb-14">
                      <div className="btn" onClick={handleSubmit}>Login</div>
                      <div className="form-check ms-3">
                        <input type="checkbox" className="form-check-input" id="remember_pwsd" />
                        <label className="form-check-label" htmlFor="remember_pwsd">Remember Me</label>
                      </div>
                    </div>
                    <a className="lost-password" href="my-account.html">Lost your Password?</a>
                  </form>
                </div>
              </div>
              {/*== End Login Area Wrapper ==*/}
            </div>
            <div className="col-lg-6 mb-8">
              {/*== Start Register Area Wrapper ==*/}
              <div className="my-account-item-wrap">
                <h3 className="title">Register</h3>
                <div className="my-account-form">
                  <form action="#" method="post">
                    <div className="form-group mb-6">
                      <label htmlFor="register_username">Phone Number <sup>*</sup></label>
                      <input 
                      type="phone" 
                      value={registerPhone}
                      onChange={(e) => setRegisterPhone(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label htmlFor="register_pwsd">Password <sup>*</sup></label>
                      <input 
                      type="password" 
                      value={registerPassword}
                      onChange={(e) => setRegisterPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <p className="desc mb-4">Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.</p>
                      <div className="btn" onClick={handleRegister}>Register</div>
                    </div>
                  </form>
                </div>
              </div>
              {/*== End Register Area Wrapper ==*/}
            </div>
          </div>
        </div>
      </section>
      {/*== End Account Area Wrapper ==*/}
    </div>
  )
}

export default Account;