import React from 'react'
import Select from 'react-select';

const ColorSelect = ({colors, variant, setVariant, setMedia}) => {

  function find(event) {
    setVariant({ label: event.label, value: event.value, color: event.color });
    const colorItem = colors.find((element) => {
      return element._id === event.value
    })
    setMedia(colorItem.media)
  }

  const dot = (color) => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: (styles, state) => ({ ...styles, 
      backgroundColor: 'white', 
      cursor: 'pointer',
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused
      ? "#FF6565"
      : styles.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? "#FF6565"
        : styles.borderColor,
    }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "#FF6565"
      const colorAlpha = 'rgba(255, 101, 101, 0.25)'
      return { ...styles, 
        backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? color
        : isFocused
        ? colorAlpha
        : undefined,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : colorAlpha
          : undefined,
        },
        ...dot(data.color)
      }; 
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  return (
    <Select
    options={colors.map(item => ({
      value: item._id, label: item.colorTitle, color: item.colorCode
    }))}
    styles={colourStyles}
    onChange={(e) => find(e)}
    value={variant}
    />
  )
}

export default ColorSelect