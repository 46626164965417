import React from 'react'

const DiscountBanner = () => {
  return (
    <div className="container">
    {/*== Start Product Category Item ==*/}
    <a href="product.html" className="product-banner-item">
      <img src="/assets/images/shop/banner/banner.png" width={1170} height={240} alt="banner" />
    </a>
    {/*== End Product Category Item ==*/}
  </div>
  )
}

export default DiscountBanner