import { Alert, AlertTitle } from "@mui/material";
import CartItem from "components/CartItem";
import { sum } from "lodash";
import React, { useState } from "react";
import { useSelector } from "state/store";
import axiosInstance from "utils/axios";
import { BASE_URL } from "utils/constants";
import PacmanLoader from "react-spinners/PacmanLoader";

const Cart = () => {
  const { checkout } = useSelector((state) => state.beautyMall);
  const { cart } = checkout;
  const { myProfile } = useSelector((state) => state.customer);
  const { token } = useSelector((state) => state.customer);
  const [address, setAddress] = useState(myProfile?.address);
  const [displayName, setDisplayName] = useState(myProfile?.displayName);
  const [loading, setLoading] = useState(false);

  const onlinePurchase = async () => {
    setLoading(true);
    const items = [];
    cart.map((product) => {
      let item;
      if (product.variant.value == null) {
        item = {
          product: product._id,
          variant: product?.variants[0]?._id,
          quantity: product.quantity,
        };
      } else {
        item = {
          product: product._id,
          color: product.variant.value,
          quantity: product.quantity,
        };
      }
      items.push(item);
    });

    const values = {
      customer: myProfile.id,
      subTotal: sum(cart.map((product) => product.subTotal)),
      disocunt: 0,
      total: sum(cart.map((product) => product.subTotal)),
      items: items,
    };

    try {
      const options = {
        method: "POST",
        url: BASE_URL + "/checkout/createCheckout",
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.request(options);
      console.log(response.data);
      if (response.data.status) {
        setLoading(false);
        window.location.replace(response.data.data?.payment_url);
        //window.open(response.data.data?.payment_url, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const alertDialog = () => {
    return (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        This is a success alert — <strong>check it out!</strong>
      </Alert>
    );
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PacmanLoader loading={loading} size={50} color="#ff6565" />
        </div>
      )}

      {/*== Start Page Header Area Wrapper ==*/}
      <nav aria-label="breadcrumb" className="breadcrumb-style1">
        <div className="container">
          <ol className="breadcrumb justify-content-center">
            <li className="breadcrumb-item">
              <a href="/home">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Cart
            </li>
          </ol>
        </div>
      </nav>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Product Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="shopping-cart-form table-responsive">
            <form action="#" method="post">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th className="product-remove">&nbsp;</th>
                    <th className="product-thumbnail">&nbsp;</th>
                    <th className="product-name">Product</th>
                    <th className="product-price">Price</th>
                    <th className="product-quantity">Quantity</th>
                    <th className="product-subtotal">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item) => {
                    return <CartItem key={item._id} item={item} />;
                  })}
                  <tr className="tbody-item-actions">
                    <td colSpan={6}>
                      <button
                        type="submit"
                        className="btn-update-cart disabled"
                        disabled
                      >
                        Clear cart
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="coupon-wrap">
                <h4 className="title">Shipping To</h4>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <button type="button" className="btn-coupon">
                  Change Address
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="cart-totals-wrap">
                <h2 className="title">Cart totals</h2>
                <table>
                  <tbody>
                    <tr className="cart-subtotal">
                      <th>Subtotal</th>
                      <td>
                        <span className="amount">
                          ৳{sum(cart.map((product) => product.subTotal))}
                        </span>
                      </td>
                    </tr>
                    <tr className="shipping-totals">
                      <th>Shipping</th>
                      <td>
                        <ul className="shipping-list">
                          {/* <li className="radio">
                            <input type="radio" name="shipping" id="radio1" defaultChecked />
                            <label htmlFor="radio1">Flat rate: <span>$3.00</span></label>
                          </li> */}
                          <li className="radio">
                            <input
                              type="radio"
                              name="shipping"
                              id="radio1"
                              defaultChecked
                            />
                            <label htmlFor="radio2">Free shipping</label>
                          </li>
                          {/* <li className="radio">
                            <input type="radio" name="shipping" id="radio3" />
                            <label htmlFor="radio3">Local pickup</label>
                          </li> */}
                        </ul>
                        <p className="destination">
                          Shipping to <strong>Dhaka</strong>.
                        </p>
                        {/* <div type='button' className="btn-shipping-address">Change address</div> */}
                      </td>
                    </tr>
                    <tr className="order-total">
                      <th>Total</th>
                      <td>
                        <span className="amount">
                          ৳{sum(cart.map((product) => product.subTotal))}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-end">
                  <div
                    type="button"
                    className="checkout-button"
                    onClick={() => {
                      onlinePurchase();
                    }}
                    // onClick={() => {
                    //   alert(
                    //     "Congratulations!! This is the first order of The Beauty Mall. We will deliver your order in the blink of an eye."
                    //   );
                    //   window.location.href = "/home";
                    // }}
                  >
                    Proceed to checkout
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Product Area Wrapper ==*/}
    </div>
  );
};

export default Cart;
