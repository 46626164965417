import ProductDetailsCarousel from "components/ProductDetailsCarousel";
import { RatingStar, ReviewStar } from "components/RatingStar";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { addCart, getSingleProduct } from "state/slices/beautyMall";
import { useDispatch, useSelector } from "state/store";
import { NewItem } from "utils/utils";
import ColorSelect from "components/ColorSelect";
import { IMAGE_BASE_URL } from "utils/constants";
import DiscountBanner from "components/DiscountBanner";
import Product from "components/Product";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selects, setSelects] = useState();
  const [count, setCount] = useState(1);
  const { id } = useParams();
  const { product } = useSelector((state) => state.beautyMall);
  const { colors } = useSelector((state) => state.beautyMall);
  const { variants } = useSelector((state) => state.beautyMall);
  const { relatedProducts } = useSelector((state) => state.beautyMall);
  const [variant, setVariant] = useState();
  const [media, setMedia] = useState([]);

  let reviewCount = 0;
  let average = 0.0;
  if (Array.isArray(product?.review)) {
    if (product?.review.length > 0) {
      reviewCount = product?.review[0]?.count;
      average = product?.review[0]?.average;
    }
  }

  function colorClicked(item) {
    setVariant({
      label: item.colorTitle,
      value: item._id,
      color: item.colorCode,
    });
    const colorItem = colors.find((element) => {
      return element._id === item._id;
    });
    setMedia(colorItem.media);
  }

  const onAddToCart = (item) => {
    dispatch(addCart(item));
  };

  const handleAddToCart = (item) => {
    onAddToCart({
      ...item,
      subTotal: item.discountedPrice * count,
      quantity: count,
      variant: variant,
    });
  };

  useEffect(() => {
    dispatch(getSingleProduct(id, setVariant, setMedia));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item">
                    <a className="text-dark" href="index.html">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active text-dark"
                    aria-current="page"
                  >
                    Product Detail
                  </li>
                </ol>
                <h2 className="page-header-title">Product Detail</h2>
              </div>
            </div>
            <div className="col-md-7">
              {/* <h5 className="showing-pagination-results mt-5 mt-md-9 text-center text-md-end">Showing Single Product</h5> */}
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Product Details Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="row product-details">
            <div className="col-lg-6">
              {typeof media !== "undefined" ? (
                <div className="product-details-thumb">
                  <ProductDetailsCarousel media={media} />
                  <NewItem createdDate={product?.createdAt} />
                </div>
              ) : null}
            </div>
            <div className="col-lg-6">
              <div className="product-details-content">
                <a
                  href
                  onClick={() => {
                    navigate(`/brand/${product?.brand[0]?.name}`, {
                      state: {
                        id: product?.brand[0]?._id,
                      },
                    });
                  }}
                >
                  <h5 className="product-details-collection">
                    <u>{product?.brand[0]?.name}</u>
                  </h5>
                </a>
                <h3 className="product-details-title">{product?.name}</h3>
                <div className="product-details-review mb-7">
                  <div className="product-review-icon">
                    <ReviewStar rating={average} />
                  </div>
                  <button type="button" className="product-review-show">
                    {reviewCount} reviews
                  </button>
                </div>
                {product?.summary && (
                  <div className="mb-7">{parse(product?.summary)}</div>
                )}
                <div className="product-details-pro-qty">
                  <div className="pro-qty">
                    <input
                      type="text"
                      title="Quantity"
                      defaultValue={1}
                      value={count}
                    />
                    <div
                      className="dec qty-btn"
                      onClick={() => setCount(Math.max(count - 1, 1))}
                    >
                      -
                    </div>
                    <div
                      className="inc qty-btn"
                      onClick={() => setCount(count + 1)}
                    >
                      +
                    </div>
                  </div>
                </div>
                {colors.length > 0 ? (
                  <ColorSelect
                    colors={colors}
                    variant={variant}
                    setVariant={setVariant}
                    setMedia={setMedia}
                  />
                ) : (
                  variants.map((item) => {
                    return (
                      <div
                        key={item?._id}
                        style={{
                          backgroundColor:
                            item?._id === variant?.value
                              ? "black"
                              : "transparent",
                          borderRadius: 20,
                          display: "inline-block",
                          width: 100,
                          height: 40,
                          borderStyle: "solid",
                          borderWidth: "1px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 7,
                            color:
                              item?._id === variant?.value ? "white" : "black",
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setVariant({
                              label: item.size,
                              value: item._id,
                              color: "#FFFFFF",
                            });
                            setMedia(item?.media);
                          }}
                        >
                          {item?.size} {item?.unit[0]?.name}
                        </div>
                      </div>
                    );
                  })
                )}
                {/* {typeof variants !== "undefined" ? console.log(variants) : null} */}
                {colors.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      style={{
                        backgroundColor:
                          item._id === variant?.value
                            ? "#000000"
                            : "transparent",
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        display: "inline-block",
                        marginTop: 20,
                        marginLeft: 5,
                      }}
                    >
                      <div
                        style={{
                          width: 45,
                          height: 45,
                          backgroundColor: "white",
                          margin: 2.5,
                          borderRadius: "50%",
                          padding: 2.5,
                        }}
                      >
                        <div
                          onClick={() => {
                            colorClicked(item);
                          }}
                          style={{
                            backgroundColor: item.colorCode,
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
                <div className="product-details-action">
                  <div className="prices">
                    <span className="price">৳{product?.discountedPrice}</span>
                    <span className="price-old">৳{product?.regularPrice}</span>
                    <span
                      style={{
                        color: "#ff6565",
                        fontSize: 12,
                        paddingLeft: 8,
                      }}
                    >
                      (
                      {(
                        ((product?.regularPrice - product?.discountedPrice) /
                          product?.regularPrice) *
                        100
                      ).toFixed(2)}
                      %)
                    </span>
                  </div>
                  <div className="product-details-cart-wishlist">
                    <button
                      type="button"
                      className="btn-wishlist"
                      data-bs-toggle="modal"
                      data-bs-target="#action-WishlistModal"
                    >
                      <i className="fa fa-heart-o" />
                    </button>
                    <button
                      type="button"
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#action-CartAddModal"
                      onClick={() => {
                        handleAddToCart(product);
                      }}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div
                className="nav product-details-nav"
                id="product-details-nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="specification-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#specification"
                  type="button"
                  role="tab"
                  aria-controls="specification"
                  aria-selected="false"
                >
                  Specification
                </button>
                <button
                  className="nav-link"
                  id="review-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#review"
                  type="button"
                  role="tab"
                  aria-controls="review"
                  aria-selected="true"
                >
                  Review
                </button>
              </div>
              <div className="tab-content" id="product-details-nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="specification"
                  role="tabpanel"
                  aria-labelledby="specification-tab"
                >
                  <ul className="product-details-info-wrap">
                    <li>
                      <span>
                        <h6>Size</h6>
                      </span>
                      <p>
                        {variant?.label} {product?.variants[0]?.unit[0]?.name}
                      </p>
                    </li>
                    <li>
                      <span>
                        <h6>How To Use</h6>
                      </span>
                      {product?.howToUse && (
                        <div>{parse(product?.howToUse)}</div>
                      )}
                    </li>
                    <li>
                      <span>
                        <h6>Ingredients</h6>
                      </span>
                      {product?.ingredients && (
                        <div>{parse(product?.ingredients)}</div>
                      )}
                    </li>
                  </ul>
                  <div
                    dangerouslySetInnerHTML={{ __html: product?.details }}
                  ></div>
                </div>
                <div
                  className="tab-pane"
                  id="review"
                  role="tabpanel"
                  aria-labelledby="review-tab"
                >
                  {product?.reviews.map((reviewItem) => {
                    return (
                      <div className="product-review-item">
                        <div className="product-review-top">
                          <div className="product-review-thumb">
                            <img
                              src="/assets/images/shop/product-details/comment1.webp"
                              alt="Images"
                            />
                          </div>
                          <div className="product-review-content">
                            <span className="product-review-name">
                              {reviewItem?.fullName}
                            </span>
                            <span className="product-review-designation"></span>
                            <div className="product-review-icon">
                              <ReviewStar rating={reviewItem?.rating} />
                            </div>
                          </div>
                        </div>
                        <p className="desc">{reviewItem?.feedback}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="product-reviews-form-wrap">
                <h4 className="product-form-title">Leave a replay</h4>
                <div className="product-reviews-form">
                  <form action="#">
                    <div className="form-input-item">
                      <textarea
                        className="form-control"
                        placeholder="Enter you feedback"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-input-item">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="form-input-item">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="form-input-item">
                      <div className="form-ratings-item">
                        <select
                          value={selects}
                          onChange={(e) => setSelects(e.target.value)}
                          className="select-ratings"
                        >
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                        </select>
                        <span className="title">Provide Your Ratings</span>
                        <div className="product-ratingsform-form-wrap">
                          <RatingStar key={selects} rating={selects} />
                        </div>
                      </div>
                    </div>
                    <div className="form-input-item mb-0">
                      <button type="submit" className="btn">
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Product Details Area Wrapper ==*/}

      {/*== Start Product Banner Area Wrapper ==*/}
      <div className="container">
        <DiscountBanner />
      </div>
      {/*== End Product Banner Area Wrapper ==*/}

      {/*== Start Product Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="title">Related Products</h2>
                <p className="m-0">You will also like this products.</p>
              </div>
            </div>
          </div>
          <div className="row mb-n4 mb-sm-n10 g-3 g-sm-6">
            {relatedProducts.map((item) => {
              return <Product key={item._id} item={item} />;
            })}
          </div>
        </div>
      </section>
      {/*== End Product Area Wrapper ==*/}

      {/*== Start Product Quick Wishlist Modal ==*/}
      <aside
        className="product-action-modal modal fade"
        id="action-WishlistModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="product-action-view-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <i className="fa fa-times" />
                </button>
                <div className="modal-action-messages">
                  <i className="fa fa-check-square-o" /> Added to wishlist
                  successfully!
                </div>
                <div className="modal-action-product">
                  <div className="thumb">
                    <img
                      style={{
                        background: "#DFE3E8",
                        borderRadius: "5px",
                      }}
                      src={`${IMAGE_BASE_URL}${media[0]?.fileName}`}
                      alt="Organic Food Juice"
                      width={466}
                      height={320}
                    />
                  </div>
                  <h4 className="product-name">{product?.name}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {/*== End Product Quick Wishlist Modal ==*/}

      {/*== Start Product Quick Add Cart Modal ==*/}
      <aside
        className="product-action-modal modal fade"
        id="action-CartAddModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="product-action-view-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <i className="fa fa-times" />
                </button>
                <div className="modal-action-messages">
                  <i className="fa fa-check-square-o" /> Added to cart
                  successfully!
                </div>
                <div className="modal-action-product">
                  <div className="thumb">
                    <img
                      style={{
                        background: "#DFE3E8",
                        borderRadius: "5px",
                      }}
                      src={`${IMAGE_BASE_URL}${media[0]?.fileName}`}
                      alt="cart"
                      width={466}
                      height={320}
                    />
                  </div>
                  <h4 className="product-name">{product?.name}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {/*== End Product Quick Add Cart Modal ==*/}
    </div>
  );
};

export default ProductDetails;
