import Footer from 'components/Footer';
import Header from 'components/Header';
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom';

const Layout = () => {

  const [showButton, setShowButton] = useState("");
  const handleScrollToTop = ( ) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    };

  useEffect(() => {
    const handleScrollButtonVisiblity = () => {
      window.pageYOffset > 400 ? setShowButton(true) : setShowButton( false);
    };
    window.addEventListener('scroll', handleScrollButtonVisiblity);
    return () => {
      window.removeEventListener( 'scroll', handleScrollButtonVisiblity);
    };
  }, []);


  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
      {/* <div id="scroll-to-top" className="scroll-to-top"><span className="fa fa-angle-up" /></div> */}
      {showButton && (
      <div className={`scrollToTop`} onClick={handleScrollToTop}>
        <span class="fa fa-angle-up scroll-to-top show"></span>
      </div>
      )}
    </div>
  );
};

export default Layout;