import { IconButton } from "@mui/material";
import ButtonAnimate from "components/animate/ButtonAnimate";
import { forwardRef } from "react";
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const MIconButton = forwardRef(({ children, ...other }, ref) => (
  <ButtonAnimate>
    <IconButton ref={ref} {...other}>
      {children}
    </IconButton>
  </ButtonAnimate>
));

MIconButton.propTypes = {
  children: PropTypes.node
};

export default MIconButton;