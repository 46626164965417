import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { BASE_URL } from "utils/constants";

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  orders: [],
  token: null,
  wishlists: [],
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // SET LOGIN
    setLogin(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload.customer;
      state.token = action.payload.token;
    },

    // SET LOGIN
    setLogout(state) {
      state.isLoading = false;
      state.myProfile = null;
      state.token = null;
    },

    // SET PROFILE
    setProfile(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload.customer;
    },

    //SET WISHLIST
    getWishlists(state, action) {
      state.isLoading = false;
      state.wishlists = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLogin, setLogout, setProfile } = slice.actions;

// -------------------------------------------------------------
// * GET PROFILE
// -------------------------------------------------------------

export function getProfile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: "GET",
        url: BASE_URL + `/customer/${id}`,
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getProfileSuccess(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------------------------------------------
// * GET ORDERS BY CUSTOMER ID
// -------------------------------------------------------------

export function getOrders(id, token) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/sales/orders",
        params: { customerId: id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getOrdersSuccess(response.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------------------------------------------
// * UPDATE PROFILE
// -------------------------------------------------------------

export function updateProfile(data, token) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: "PATCH",
        url: BASE_URL + "/customer/editCustomer",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(options);
      dispatch(slice.actions.setProfile(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------------------------------------------
// * GET WISHLISTS
// -------------------------------------------------------------

export function wishlistItems(token) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/wishlist/wishlists",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getWishlists(response.data.wishlists));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
