import { sum } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "state/store";
import SideCartItem from "./SideCartItem";
import {
  getBrands,
  getCategories,
  getHeaders,
  getProducts,
} from "state/slices/beautyMall";
import { IMAGE_BASE_URL } from "utils/constants";

const headerItems = [
  {
    text: "What's New",
  },
  {
    text: "Categories",
  },
  {
    text: "Brands",
  },
  {
    text: "Best Sellers",
  },
  {
    text: "Shop",
  },
];

const Header = () => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { checkout } = useSelector((state) => state.beautyMall);
  const { cart } = checkout;
  const { token } = useSelector((state) => state.customer);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.beautyMall);
  const [isSearchShow, setIsSearchShow] = useState(false);
  const { categories } = useSelector((state) => state.beautyMall);
  const { brands } = useSelector((state) => state.beautyMall);
  const { headers } = useSelector((state) => state.beautyMall);

  useEffect(() => {
    dispatch(getHeaders());
    dispatch(getCategories());
    dispatch(getBrands());
    setActive(pathname.substring(1));
    const delayDebounceFn = setTimeout(() => {
      const params = {
        page: 1,
        limit: 20,
        searchQuery: search,
      };
      dispatch(getProducts(params));
      if (search === "") {
        setIsSearchShow(false);
      } else {
        setIsSearchShow(true);
      }
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
  }, [pathname, search, dispatch]);

  const handleSearch = async () => {
    // const values = {
    //   phone: registerPhone,
    //   password: registerPassword,
    // };

    // await register(values);
    console.log(search);
  };

  return (
    <div>
      <header className="header-area sticky-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-5 col-sm-6 col-lg-3">
              <div className="header-logo">
                <a href="/home" className="home">
                  <img
                    className="logo-main"
                    src="/assets/images/tbm.png"
                    width="240"
                    height="68"
                    alt="Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="header-navigation">
                <ul className="main-nav justify-content-start">
                  {headerItems.map(({ text }) => {
                    const lcText = text.toLowerCase().replace(/\s+/g, "");
                    if (lcText === "categories") {
                      return (
                        <li key={text} class="has-submenu">
                          <a
                            href
                            // onClick={() => {
                            //   navigate(`/${lcText}`);
                            //   setActive(lcText);
                            // }}
                          >
                            {lcText}{" "}
                            <img
                              src="/assets/images/icons/chevron-down-solid.svg"
                              alt="chevron-down"
                              width={10}
                              height={10}
                            />
                          </a>
                          <ul class="submenu-nav">
                            {categories?.map((item) => {
                              return (
                                <li key={item?._id}>
                                  <a
                                    href
                                    onClick={() => {
                                      navigate(`category/${item?.name}`, {
                                        state: {
                                          id: item?._id,
                                        },
                                      });
                                    }}
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    } else if (lcText === "brands") {
                      return (
                        <li key={text} class="has-submenu position-static">
                          <a href>
                            {lcText}{" "}
                            <img
                              src="/assets/images/icons/chevron-down-solid.svg"
                              alt="chevron-down"
                              width={10}
                              height={10}
                            />
                          </a>
                          <ul class="submenu-nav-mega">
                            <div
                              className="row"
                              style={{
                                background: "white",
                              }}
                            >
                              {brands.map((item) => {
                                return (
                                  <li key={item?.id}>
                                    <ul>
                                      <li>
                                        <a
                                          href
                                          onClick={() => {
                                            navigate(`brand/${item?.name}`, {
                                              state: {
                                                id: item?.id,
                                              },
                                            });
                                          }}
                                          className="d-block"
                                          style={{
                                            fontSize: 14,
                                          }}
                                        >
                                          {item?.name}
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                );
                              })}
                            </div>
                          </ul>
                        </li>
                      );
                    } else {
                      return (
                        <li key={text}>
                          <a
                            style={{
                              color: active === lcText ? "#ff6969" : "#000000",
                            }}
                            href={`/${lcText}`}
                            onClick={() => {
                              navigate(`/${lcText}`);
                              setActive(lcText);
                            }}
                          >
                            {text}
                          </a>
                        </li>
                      );
                    }
                  })}
                  <li>
                    <a
                      style={{
                        color:
                          active === headers[0]?.name ? "#ff6969" : "#000000",
                      }}
                      href
                      onClick={() => {
                        navigate("/sale");
                        setActive(headers[0]?.name);
                      }}
                    >
                      {headers[0]?.name}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-7 col-sm-6 col-lg-3">
              <div className="header-action justify-content-end">
                <div
                  className="product-sidebar-widget"
                  style={{
                    height: 50,
                    marginBottom: 8,
                  }}
                >
                  <div className="product-widget-search">
                    <form action="#">
                      <input
                        type="search"
                        value={search}
                        placeholder="Search Here"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <button type="submit" onClick={handleSearch}>
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>

                  {isSearchShow && (
                    <div
                      style={{
                        zIndex: 10,
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        //backgroundColor: "#b8b1b1",
                        position: "absolute",
                        width: 405,
                        minHeight: 100,
                        borderRadius: "10px",
                        padding: "10px",
                        backdropFilter: "blur(10px)",
                        boxShadow: "10px 10px 10px rgba(30,30,30,0.5)",
                      }}
                    >
                      {products.map((item) => {
                        return (
                          <a
                            key={item._id}
                            className="d-block"
                            href="#0"
                            onClick={() => {
                              setIsSearchShow(false);
                              setSearch("");
                              navigate(`/product/${item._id}`);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                className="thumb"
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                {item?.variants[0]?.colors.length > 0 ? (
                                  <img
                                    style={{
                                      background: "#DFE3E8",
                                      borderRadius: "5px",
                                      height: 50,
                                      width: 50,
                                    }}
                                    src={
                                      IMAGE_BASE_URL +
                                      item?.variants[0]?.colors[0]?.media[0]
                                        ?.fileName
                                    }
                                    alt="Organic Food Juice"
                                  />
                                ) : (
                                  <img
                                    style={{
                                      background: "#DFE3E8",
                                      borderRadius: "5px",
                                      height: 50,
                                      width: 50,
                                    }}
                                    src={
                                      IMAGE_BASE_URL +
                                      item?.variants[0]?.media[0]?.fileName
                                    }
                                    alt="Organic Food Juice"
                                  />
                                )}
                              </div>
                              <h6
                                style={{
                                  marginLeft: 10,
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: 300,
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  color: "black",
                                }}
                              >
                                {item.name}
                              </h6>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>

                <button
                  className="header-action-btn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#AsideOffcanvasCart"
                  aria-controls="AsideOffcanvasCart"
                >
                  <span className="icon">
                    <img
                      id="image0_504:9"
                      width="30"
                      height="30"
                      src="/assets/images/icons/icons8-cart-66.png"
                    />
                  </span>
                </button>
                <div
                  className="header-action-btn"
                  type="button"
                  onClick={() => {
                    token === null
                      ? navigate(`/account`)
                      : navigate(`/customer`);
                  }}
                >
                  {token ? (
                    <span className="icon">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <rect
                          className="icon-rect"
                          width="30"
                          height="30"
                          fill="url(#pattern3)"
                        />
                        <defs>
                          <pattern
                            id="pattern3"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                          >
                            <use
                              xlinkHref="#image0_504:10"
                              transform="scale(0.0333333)"
                            />
                          </pattern>
                          <image
                            id="image0_504:10"
                            width="30"
                            height="30"
                            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABEUlEQVRIie3UMUoDYRDF8Z8psqUpLBRrBS+gx7ATD6E5iSjeQQ/gJUzEwmChnZZaKZiQ0ljsLkhQM5/5Agr74DX7DfOfgZ1Hoz+qAl30Marcx2H1thCtY4DJN76parKqmAH9DM+6eTcArX2QE3yVAO7lBA8TwMNIw6UgeJI46My+rWCjUQL0LVIUBd8lgEO1UfBZAvg8oXamCuWNRu64nRNMmUo/wReSXLXayoDoKc9miMvqW/ZNG2VRNLla2MYudrCFTvX2intlnl/gGu/zDraGYzyLZ/UTjrD6G2AHpxgnAKc9xgmWo9BNPM4BnPYDNiLg24zQ2oNpyFdZvRKZLlGhnvvKPzXXti/Yy7hEo3+iD9EHtgdqxQnwAAAAAElFTkSuQmCC"
                          />
                        </defs>
                      </svg>
                    </span>
                  ) : (
                    <span>
                      <h5>Login</h5>
                    </span>
                  )}
                </div>

                <button
                  className="header-menu-btn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#AsideOffcanvasMenu"
                  aria-controls="AsideOffcanvasMenu"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/*== Start Aside Search Form ==*/}
      <aside
        className="aside-search-box-wrapper offcanvas offcanvas-top"
        tabIndex={-1}
        id="AsideOffcanvasSearch"
        aria-labelledby="offcanvasTopLabel"
      >
        <div className="offcanvas-header">
          <h5 className="d-none" id="offcanvasTopLabel">
            Aside Search
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="fa fa-close" />
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container pt--0 pb--0">
            <div className="search-box-form-wrap">
              <div className="search-note">
                <p>Start typing and press Enter to search</p>
              </div>
              <form action="#" method="post">
                <div className="aside-search-form position-relative">
                  <label htmlFor="SearchInput" className="visually-hidden">
                    Search
                  </label>
                  <input
                    id="SearchInput"
                    type="search"
                    className="form-control"
                    placeholder="Search entire store…"
                  />
                  <button className="search-button" type="submit">
                    <i className="fa fa-search" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </aside>
      {/*== End Aside Search Form ==*/}

      {/*== Start Aside Cart ==*/}
      <aside
        className="aside-cart-wrapper offcanvas offcanvas-end"
        tabIndex={-1}
        id="AsideOffcanvasCart"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h1 className="d-none" id="offcanvasRightLabel">
            Shopping Cart
          </h1>
          <button
            className="btn-aside-cart-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Shopping Cart <i className="fa fa-chevron-right" />
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="aside-cart-product-list">
            {cart.map((item) => {
              return <SideCartItem item={item} />;
            })}
          </ul>
          <p className="cart-total">
            <span>Subtotal:</span>
            <span className="amount">
              ৳{sum(cart.map((product) => product.subTotal))}
            </span>
          </p>
          <a className="btn-total" href="/cart">
            View cart
          </a>
          <a className="btn-total" href="product-checkout.html">
            Checkout
          </a>
        </div>
      </aside>
      {/*== End Aside Cart ==*/}

      {/*== Start Aside Menu ==*/}
      <aside
        className="off-canvas-wrapper offcanvas offcanvas-start"
        tabIndex={-1}
        id="AsideOffcanvasMenu"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h1 className="d-none" id="offcanvasExampleLabel">
            Aside Menu
          </h1>
          <button
            className="btn-menu-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            menu <i className="fa fa-chevron-left" />
          </button>
        </div>
        <div className="offcanvas-body">
          <div id="offcanvasNav" className="offcanvas-menu-nav">
            <ul>
              {headerItems.map(({ text }) => {
                const lcText = text.toLowerCase();
                return (
                  <li>
                    <a
                      style={{
                        color: active === lcText ? "#ff6969" : "#FFFFFF",
                      }}
                      href={`/${lcText}`}
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                    >
                      {text}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </aside>
      {/*== End Aside Menu ==*/}
    </div>
  );
};

export default Header;
