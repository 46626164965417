import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Parallax, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import DiscountBanner from "components/DiscountBanner";
import Product from "components/Product";
import { useDispatch, useSelector } from "state/store";
import { getHomeData, getNewProducts } from "state/slices/beautyMall";
import { useEffect } from "react";
import { IMAGE_BASE_URL } from "utils/constants";
import { motion } from "framer-motion";

const Home = () => {
  const latestBlogs = [
    {
      id: "1",
      title: "16 clean beauty brands to try now.",
      author: "ALICIA",
      date: "January 1, 2023",
      categoryName: "SKINCARE",
      image: "/assets/images/blog/blog4.png",
      color: "#FF9C9C",
      hoverColor: "#ff6969",
    },
    {
      id: "2",
      title: "Facial Scrub is natural treatment for face.",
      author: "TOMAS DE MOMEN",
      date: "NOVEMBER 13, 2022",
      categoryName: "Beauty",
      image: "/assets/images/blog/blog5.png",
      color: "#A49CFF",
      hoverColor: "##7569ff",
    },
    {
      id: "3",
      title: "Benefit of Hot Ston Spa for your health & life.",
      author: "TOMAS DE MOMEN",
      date: "JULY 20, 2022",
      categoryName: "Beauty",
      image: "/assets/images/blog/blog6.png",
      color: "#9CDBFF",
      hoverColor: "#69c8ff",
    },
  ];

  const dispatch = useDispatch();
  const { newProducts } = useSelector((state) => state.beautyMall);
  const { sliders } = useSelector((state) => state.beautyMall);
  const { saleItems } = useSelector((state) => state.beautyMall);

  useEffect(() => {
    const params = {
      page: 1,
      limit: 18,
      searchQuery: "",
      sortBy: 1,
    };
    dispatch(getNewProducts(params));
    dispatch(getHomeData());
  }, [dispatch]);

  return (
    <div>
      <section className="hero-two-slider-area position-relative">
        <Swiper
          spaceBetween={60}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          modules={[Autoplay, Parallax, Pagination]}
          className="mySwiper hero-two-slider-pagination"
        >
          {sliders.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <div className="swiper-slide hero-two-slide-item">
                  <div className="container">
                    <div className="row align-items-center position-relative">
                      <div className="col-12 col-md-6">
                        <div className="hero-two-slide-content">
                          <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            transition={{ delay: 1, duration: 1.5 }}
                            variants={{
                              hidden: { opacity: 0, x: -50, y: -5 },
                              visible: { opacity: 1, x: 0, y: -5 },
                            }}
                          >
                            <div className="hero-two-slide-text-img">
                              <img
                                src="assets/images/slider/text-light.webp"
                                width={427}
                                height={232}
                                alt="text-light"
                              />
                            </div>
                          </motion.div>
                          <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            transition={{ delay: 1.9, duration: 1.5 }}
                            variants={{
                              hidden: { opacity: 0, y: 50 },
                              visible: { opacity: 1, y: 0 },
                            }}
                          >
                            <h2 className="hero-two-slide-title">
                              {item?.product?.name}
                            </h2>
                          </motion.div>
                          <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            transition={{ delay: 2.5, duration: 1.5 }}
                            variants={{
                              hidden: { opacity: 0, y: 50 },
                              visible: { opacity: 1, y: 0 },
                            }}
                          >
                            <p className="hero-two-slide-desc">
                              {item?.product?.summary}
                            </p>
                          </motion.div>
                          <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ amount: 0.5 }}
                            transition={{ delay: 2.9, duration: 1.5 }}
                            variants={{
                              hidden: { opacity: 0, y: 50 },
                              visible: { opacity: 1, y: 0 },
                            }}
                          >
                            <div className="hero-two-slide-meta">
                              <a
                                className="btn btn-border-primary"
                                href={`/product/${item?.product?.id}`}
                              >
                                BUY NOW
                              </a>
                              <a
                                className="ht-popup-video"
                                data-fancybox
                                data-type="iframe"
                                href={item?.coverVideo}
                              >
                                <i className="fa fa-play icon" />
                                <span>Play Now</span>
                              </a>
                            </div>
                          </motion.div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ delay: 0.5, duration: 2 }}
                        >
                          <div className="hero-two-slide-thumb">
                            <img
                              src={IMAGE_BASE_URL + item.coverImage}
                              width={690}
                              height={690}
                              alt="slider3"
                            />
                          </div>
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>

      {/* SALE PRODUCT SECTION */}
      <section className="section-space">
        <div className="container">
          <div className="row">
            {saleItems.map((item) => {
              return (
                <div className="col-sm-6 col-lg-4 mt-lg-6 mt-6" key={item.id}>
                  {/*== Start Product Category Item ==*/}
                  <a href="product.html" className="product-banner-item">
                    <img
                      src={IMAGE_BASE_URL + item.coverImage}
                      width={370}
                      height={370}
                      alt="6"
                    />
                  </a>
                  {/*== End Product Category Item ==*/}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* BEST PRODUCT */}
      <section className="section-space pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="title">Best Product</h2>
                <p className="m-0">
                  Discover our latest products and collections – soon to be your
                  biggest obsessions.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-n4 mb-sm-n10 g-3 g-sm-6">
            {newProducts.map((item) => {
              return <Product key={item._id} item={item} />;
            })}
          </div>
        </div>
      </section>
      {/*== End Product Area Wrapper ==*/}

      {/* Discount Banner */}
      <section class="section-space pt-0">
        <DiscountBanner />
      </section>

      {/*== Start Product Area Wrapper ==*/}
      <section className="section-space pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 className="title">Top Sale Products</h2>
                <p className="m-0">Discover our best selling products.</p>
              </div>
            </div>
          </div>
          <div className="row mb-n4 mb-sm-n10 g-3 g-sm-6">
            {newProducts.map((item) => {
              return <Product key={item._id} item={item} />;
            })}
          </div>
        </div>
      </section>
      {/*== End Product Area Wrapper ==*/}

      {/*== Start Blog Area Wrapper ==*/}
      <section className="section-space pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h2 className="title">Blog posts</h2>
                <p>
                  Read up on the best skincare advice or join us for a virtual
                  master class experience.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-n9">
            {latestBlogs.map((blog) => {
              return (
                <div className="col-sm-6 col-lg-4 mb-8" key={blog.id}>
                  {/*== Start Blog Item ==*/}
                  <div className="post-item">
                    <a href="blog-details.html" className="thumb">
                      <img
                        src={blog.image}
                        width={370}
                        height={320}
                        alt="blog2"
                      />
                    </a>
                    <div className="content">
                      <a
                        style={{
                          backgroundColor: blog.hoverColor,
                        }}
                        className="post-category"
                        href="blog.html"
                      >
                        {blog.categoryName}
                      </a>
                      <h4 className="title">
                        <a href="blog-details.html">{blog.title}</a>
                      </h4>
                      <ul className="meta">
                        <li className="author-info">
                          <span>By:</span> <a href="blog.html">{blog.author}</a>
                        </li>
                        <li className="post-date">{blog.date}</li>
                      </ul>
                    </div>
                  </div>
                  {/*== End Blog Item ==*/}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/*== End Blog Area Wrapper ==*/}
    </div>
  );
};

export default Home;
