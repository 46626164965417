import React from 'react'

export const RatingStar = ({rating}) => {
  if (rating === '01') {
    return (
      <div className="product-ratingsform-form-icon">
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating === '02') {
    return (
      <div className="product-ratingsform-form-icon">
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating === '03') {
    return (
      <div className="product-ratingsform-form-icon">
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating === '04') {
    return (
      <div className="product-ratingsform-form-icon">
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating === '05') {
    return (
      <div className="product-ratingsform-form-icon">
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
      </div>
    );
  } else {
    return (
      <div className="product-ratingsform-form-icon">
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  }
}

export const ReviewStar = ({rating}) => {
  if (rating > 4.50) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
      </div>
    );
  } else if (rating > 4 && rating < 4.51) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-half-o" />
      </div>
    );
  } else if (rating > 3.50 && rating < 4.1) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating > 3 && rating < 3.51) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-half-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating > 2.50 && rating < 3.1) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating > 2 && rating < 2.51) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-half-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating > 1.50 && rating < 2.1) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating > 1 && rating < 1.51) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star-half-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating > .50 && rating < 1.1) {
    return (
      <div>
        <i className="fa fa-star" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else if (rating > 0 && rating < .51) {
    return (
      <div>
        <i className="fa fa-star-half-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  } else {
    return (
      <div>
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
        <i className="fa fa-star-o" />
      </div>
    );
  }
}

export const ReviewCountText = ({count}) => {
  if (count > 1) {
    return (
      <div className="reviews">{count} reviews</div>
    )
  } else {
    return (
      <div className="reviews">{count} review</div>
    )
  }
}