import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useSelector } from "state/store";
import { formatDate } from "utils/utils";

const Invoice = () => {
  const componentRef = useRef();
  const { position } = useParams();
  const { myProfile } = useSelector((state) => state.customer);
  const { orders } = useSelector((state) => state.customer);
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item">
                    <a className="text-dark" href="/home">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active text-dark"
                    aria-current="page"
                  >
                    Invoice
                  </li>
                </ol>
                <h2 className="page-header-title">Invoice</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Invoice Section ==*/}
      <section className="my-account-area section-space">
        <div
          className="invoice__preview"
          style={{
            padding: "1.25rem",
            backgroundColor: "#F3F4F6",
            borderRadius: "0.25rem",
          }}
        >
          <div
            ref={componentRef}
            style={{
              padding: "1.25rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "1.25rem",
              }}
            >
              <div>
                <img
                  src="/assets/images/tbm.png"
                  width="260"
                  height="68"
                  alt="No Logo"
                />
              </div>
            </div>
            <section
              style={{
                marginTop: "2.5rem",
              }}
            >
              <h2 className="text-2xl uppercase font-bold mb-1">
                {myProfile.firstName} {myProfile.lastName}
              </h2>
              <p>{myProfile.address}</p>
            </section>
            <article
              style={{
                display: "flex",
                marginTop: "2.5rem",
                marginBottom: "3.5rem",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <ul>
                <li
                  style={{
                    padding: "0.25rem",
                  }}
                >
                  {/* <Barcode
                    value={orders[position].invoiceNo}
                    height={50}
                    displayValue={false}
                  /> */}
                </li>
                <li
                  style={{
                    padding: "0.25rem",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Invoice number:
                  </span>{" "}
                  {orders[position].invoiceNo}
                </li>
                <li
                  style={{
                    padding: "0.25rem",
                    backgroundColor: "#F3F4F6",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Date:
                  </span>{" "}
                  {formatDate(orders[position].createdAt)}
                </li>
                <li
                  style={{
                    padding: "0.25rem",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Billed By:
                  </span>{" "}
                  Online Purchase
                </li>
              </ul>
            </article>
            <table
              width="100%"
              style={{
                marginBottom: "2.5rem",
              }}
            >
              <thead>
                <tr
                  style={{
                    padding: "0.25rem",
                    backgroundColor: "#E5E7EB",
                  }}
                >
                  <td
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    #
                  </td>
                  <td
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Description
                  </td>
                  <td
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Quantity
                  </td>
                  <td
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Price
                  </td>
                  <td
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Amount
                  </td>
                </tr>
              </thead>
              {orders[position].salesItems.map((item, index) => {
                return (
                  <React.Fragment key={1}>
                    <tbody>
                      <tr
                        style={{
                          height: "2.5rem",
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>
                          {item.product[0].name} ({item.color[0].colorTitle})
                        </td>
                        <td>{item.quantity}</td>
                        <td>{item.product[0].discountedPrice}</td>
                        <td>
                          {item.product[0].discountedPrice * item.quantity}
                        </td>
                      </tr>
                    </tbody>
                  </React.Fragment>
                );
              })}
            </table>
            <hr
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h6
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "1.75rem",
                  fontWeight: "700",
                }}
              >
                SubTotal
              </h6>
              <p
                className="amount"
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "1.75rem",
                  fontWeight: "700",
                }}
              >
                {orders[position].subTotal} TK
              </p>
            </div>
            <hr
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h6
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "1.75rem",
                  fontWeight: "700",
                }}
              >
                Vat
              </h6>
              <p
                className="amount"
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "1.75rem",
                  fontWeight: "700",
                }}
              >
                0 TK
              </p>
            </div>
            <hr
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h6
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "1.75rem",
                  fontWeight: "700",
                }}
              >
                Discount
              </h6>
              <p
                className="amount"
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "1.75rem",
                  fontWeight: "700",
                }}
              >
                {orders[position].disocunt} TK
              </p>
            </div>
            <hr
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            />
            <div
              style={{
                display: "flex",
                marginTop: "1.25rem",
                justifyContent: "space-between",
              }}
            >
              <h2
                style={{
                  color: "#1F2937",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  fontWeight: "700",
                }}
              >
                Total
              </h2>
              <h2
                style={{
                  color: "#1F2937",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  fontWeight: "700",
                }}
              >
                {orders[position].total} TK
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "2.5rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                <span
                  style={{
                    fontWeight: "700",
                  }}
                >
                  Powered By:
                </span>{" "}
                <a href="https://maxobyte.com">MaxoByte</a> 01717489444
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <button
                  style={{
                    marginLeft: "1.25rem",
                    backgroundColor: isHover ? "#60A5FA" : "#3B82F6",
                    color: isHover ? "#3B82F6" : "#ffffff",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    marginTop: "1.25rem",
                    fontWeight: "700",
                    borderRadius: "0.25rem",
                    boxShadow:
                      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                    borderWidth: "2px",
                    borderColor: "#3B82F6",
                    transitionProperty: "all",
                    transitionDuration: "300ms",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Print / Download
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </section>
      {/*== End Invoice Section ==*/}
    </div>
  );
};

export default Invoice;
