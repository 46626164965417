import { alpha, Box, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import CarouselControlsArrowsIndex from './CarouselControlsArrowsIndex';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_BASE_URL } from 'utils/constants';

const THUMB_SIZE = 64;

const RootStyle = styled('div')(({ theme }) => ({
  '& .slick-slide': {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    '&:focus': { outline: 'none' }
  }
}));

const ThumbWrapperStyle = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  width: THUMB_SIZE,
  overflow: 'hidden',
  height: THUMB_SIZE,
  position: 'relative',
  margin: theme.spacing(0, 1),
  borderRadius: '12px',
  '&:hover': {
    opacity: 0.72,
    transition: theme.transitions.create('opacity')
  },
  '& .isActive': {
    top: 0,
    zIndex: 9,
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '12px',
    border: `solid 2px #FF6565`,
    backgroundColor: alpha(theme.palette.grey[900], 0.15)
  }
}));

const LargeImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const ThumbImgStyle = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
});

// ----------------------------------------------------------------------

LargeItem.propTypes = {
  item: PropTypes.string,
  onOpenLightbox: PropTypes.func
};

function LargeItem({ item, onOpenLightbox }) {
  return (
    <Box sx={{ paddingTop: '100%', position: 'relative' }}>
      <LargeImgStyle alt="large image" src={item} onClick={() => onOpenLightbox(item)} />
    </Box>
  );
}

ThumbnailItem.propTypes = {
  item: PropTypes.string
};

function ThumbnailItem({ item }) {
  return (
    <ThumbWrapperStyle>
      <Box className="isActive" />
      <ThumbImgStyle alt="thumb image" src={item} />
    </ThumbWrapperStyle>
  );
}

const ProductDetailsCarousel = ({media}) => {

  // console.log({
  //   media: media,
  // });

  // const images = [
  //   "/assets/images/shop/shop1.webp",
  //   "/assets/images/shop/shop2.webp",
  //   "/assets/images/shop/shop3.webp",
  //   "/assets/images/shop/shop4.webp",
  //   "/assets/images/shop/shop5.webp",
  //   "/assets/images/shop/shop6.webp",
  //   "/assets/images/shop/shop1.webp",
  //   "/assets/images/shop/shop2.webp",
  //   "/assets/images/shop/shop3.webp",
  //   "/assets/images/shop/shop4.webp",
  //   "/assets/images/shop/shop5.webp",
  //   "/assets/images/shop/shop6.webp",
  // ]
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nav1, setNav1] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const settings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCurrentIndex(next)
  };

  const settings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
  };

  useEffect(() => {
    setNav1(slider1.current);
  }, [currentIndex]);

  const handlePrevious = () => {
    slider2.current.slickPrev();
  };

  const handleNext = () => {
    slider2.current.slickNext();
  };

  return (
    <RootStyle>
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            zIndex: 0,
            borderRadius: 2,
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: "#DFE3E8",
          }}
        >
          <Slider {...settings1} asNavFor={nav1} ref={slider1}>
            {media?.map((item) => (
              <LargeItem key={item?.id} item={ IMAGE_BASE_URL + item?.fileName } />
            ))}
          </Slider>
          <CarouselControlsArrowsIndex
            index={currentIndex}
            total={media?.length}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </Box>
      </Box>

      <Box
        sx={{
          my: 3,
          mx: 'auto',
          '& .slick-current .isActive': { opacity: 1 },
          ...(media?.length > 2 && {
            position: 'relative',
            '&:before, &:after': {
              top: 0,
              zIndex: 9,
              content: "''",
              height: '100%',
              position: 'realtive',
              width: (THUMB_SIZE * 2) / 3,
              backgroundImage: (theme) =>
                `linear-gradient(to left, ${alpha('#fff', 0)} 0%, ${
                  '#fff'
                } 100%)`
            },
            '&:after': { right: 0, transform: 'scaleX(-1)' }
          })
        }}
      >
        <Slider {...settings2} asNavFor={nav1} ref={slider2}>
          {media?.map((item) => (
            <ThumbnailItem key={item?.id} item={ IMAGE_BASE_URL + item?.fileName } />
          ))}
        </Slider>
      </Box>
    </RootStyle>
  );
}

export default ProductDetailsCarousel;