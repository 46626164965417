import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import customerReducer from "state/slices/customer";
import productReducer from "state/slices/beautyMall";

const rootPersistConfig = {
  key: "root",
  storage,
  version: 1,
  keyPrefix: "redux-",
  whitelist: [],
};

const beautyMallPersistConfig = {
  key: "beautyMall",
  storage,
  version: 1,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const customerPersistConfig = {
  key: "customer",
  storage,
  version: 1,
  keyPrefix: "redux-",
  whitelist: ["myProfile", "token", "orders"],
};

const rootReducer = combineReducers({
  customer: persistReducer(customerPersistConfig, customerReducer),
  beautyMall: persistReducer(beautyMallPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
