import { createSlice } from "@reduxjs/toolkit";
import { filter, map, sum } from "lodash";

import axios from "utils/axios";
import { BASE_URL } from "utils/constants";
import { wishlistItems } from "./customer";

const initialState = {
  isLoading: false,
  error: false,
  sliders: [],
  saleItems: [],
  products: [],
  categories: [],
  product: null,
  sortBy: null,
  filters: {
    category: "All",
    colors: [],
    priceRange: "",
    rating: "",
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subTotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
  media: [],
  colors: [],
  relatedProducts: [],
  variants: [],
  message: "",
  brands: [],
  productsByCategory: [],
  productsByBrand: [],
  headers: [],
  customItems: [],
  newProducts: [],
};

const slice = createSlice({
  name: "beautyMall",
  initialState,
  reducers: {
    // * START LOADING
    startLoading: (state) => {
      state.isLoading = true;
    },

    // * HAS ERROR
    hasError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // * GET HOME SLIDERS
    getHomeSliders: (state, action) => {
      state.isLoading = false;
      state.sliders = action.payload;
    },

    // * GET HOME SLIDERS
    getHomeSaleItems: (state, action) => {
      state.isLoading = false;
      state.saleItems = action.payload;
    },

    // * GET PRODUCTS
    getProductsSuccess: (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    },

    // * GET NEW PRODUCTS
    getNewProductsSuccess: (state, action) => {
      state.isLoading = false;
      state.newProducts = [];
      state.newProducts = action.payload;
    },

    // * GET CATEGORIES
    getCategoriesSuccess: (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // * GET BRANDS
    getBrandsSuccess: (state, action) => {
      state.isLoading = false;
      state.brands = action.payload;
    },

    // * GET PRODUCTS BY CATEGORY
    getProductsByCategorySuccess: (state, action) => {
      state.isLoading = false;
      state.productsByCategory = action.payload;
    },

    // * GET PRODUCTS BY CATEGORY
    getProductsByBrandSuccess: (state, action) => {
      state.isLoading = false;
      state.productsByBrand = action.payload;
    },

    // * GET HEADERS
    getHeadersSuccess: (state, action) => {
      state.isLoading = false;
      state.headers = action.payload;
    },

    // * GET CUSTOM ITEMS
    getCustomItemsSuccess: (state, action) => {
      state.isLoading = false;
      state.customItems = action.payload;
    },

    // * GET PRODUCT
    getProductSuccess: (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
    },

    // * SORT AND FILTER PRODUCTS
    sortByProducts: (state, action) => {
      state.sortBy = action.payload;
    },

    filterProducts: (state, action) => {
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // * CHECKOUT
    getCart: (state, action) => {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart: (state, action) => {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;
      let isExisted = false;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          isExisted =
            _product._id === product._id &&
            _product.variant.value === product.variant.value;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + product.quantity,
            };
          }
          return _product;
        });
        if (!isExisted) {
          state.checkout.cart = [...state.checkout.cart, product];
        }
      }
      //state.checkout.cart = uniqBy([...state.checkout.cart, product], "_id");
    },

    deleteCart: (state, action) => {
      const product = action.payload;
      console.log({
        product: product,
      });
      const updateCart = filter(
        state.checkout.cart,
        (item) => item.variant.value !== product.variant.value
      );

      state.checkout.cart = updateCart;
    },

    resetCart: (state) => {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep: (state) => {
      state.checkout.activeStep -= 1;
    },

    onNextStep: (state) => {
      state.checkout.activeStep += 1;
    },

    onGotoStep: (state, action) => {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity: (state, action) => {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product._id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
            subTotal: product.discountedPrice * (product.quantity + 1),
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity: (state, action) => {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product._id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
            subTotal: product.discountedPrice * (product.quantity - 1),
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling: (state, action) => {
      state.checkout.billing = action.payload;
    },

    applyDiscount: (state, action) => {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping: (state, action) => {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    },

    // * PRODUCT MEDIA
    getMedia: (state, action) => {
      state.isLoading = false;
      state.media = action.payload;
    },

    // * PRODUCT COLOR
    getColors: (state, action) => {
      state.isLoading = false;
      state.colors = action.payload;
    },

    // * RELATED PRODUCT
    getRelatedProducts: (state, action) => {
      state.isLoading = false;
      state.relatedProducts = action.payload;
    },

    // * PRODUCT VARIANTS
    getVariants: (state, action) => {
      state.isLoading = false;
      state.variants = action.payload;
    },

    // * MESSAGE
    getMessage: (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    },
  },
});

export default slice.reducer;

// * ACTIONS

export const {
  getProductsSuccess,
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  decreaseQuantity,
} = slice.actions;

// ------------------------------------------------------
// * GET PRODUCTS
// ------------------------------------------------------
export function getProducts(params) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/product/products",
        params: params,
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET NEW PRODUCTS
// ------------------------------------------------------
export function getNewProducts(params) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/product/products",
        params: params,
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getNewProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET HOME DATA
// ------------------------------------------------------
export function getHomeData() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/home/homeData",
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getHomeSliders(response.data.sliders));
      dispatch(slice.actions.getHomeSaleItems(response.data.saleItems));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET CATEGORIES
// ------------------------------------------------------
export function getCategories() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/category/categories",
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getCategoriesSuccess(response.data.categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET CATEGORIES
// ------------------------------------------------------
export function getBrands() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/brand/brands",
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getBrandsSuccess(response.data.brands));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET HEADERS
// ------------------------------------------------------
export function getHeaders() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/header/headers",
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getHeadersSuccess(response.data.headers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET CUSTOM ITEMS
// ------------------------------------------------------
export function getCustomItems() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + "/custom/customItems",
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getCustomItemsSuccess(response.data.customItems));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET SINGLE PRODUCT BY ID
// ------------------------------------------------------
export function getSingleProduct(id, setVariant, setMedia) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + `/product/product/${id}`,
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getProductSuccess(response.data.product));
      // dispatch(
      //   slice.actions.getMedia(
      //     response.data.product.variants[0].colors[0].media
      //   )
      // );
      dispatch(
        slice.actions.getColors(response.data.product.variants[0].colors)
      );
      dispatch(slice.actions.getVariants(response.data.product.variants));
      dispatch(slice.actions.getRelatedProducts(response.data.relatedProducts));
      if (response.data.product.variants[0].colors.length > 0) {
        setMedia(response.data.product.variants[0].colors[0].media);
        setVariant({
          label: response.data.product.variants[0].colors[0].colorTitle,
          value: response.data.product.variants[0].colors[0]._id,
          color: response.data.product.variants[0].colors[0].colorCode,
        });
      } else {
        setMedia(response.data.product.variants[0].media);
        setVariant({
          label: response.data.product.variants[0].size,
          value: response.data.product.variants[0]._id,
          color: "#FFFFFF",
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET PRODUCT BY CATEGORY ID
// ------------------------------------------------------
export function getProductByCategory(id) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + `/category/productByCategory?id=${id}`,
      };
      const response = await axios.request(options);
      dispatch(
        slice.actions.getProductsByCategorySuccess(
          response.data.categories[0]?.products
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET PRODUCT BY BRAND ID
// ------------------------------------------------------
export function getProductByBrand(id) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: BASE_URL + `/brand/productByBrand?id=${id}`,
      };
      const response = await axios.request(options);
      dispatch(
        slice.actions.getProductsByBrandSuccess(
          response.data.brand[0]?.products
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * SAVE DELETE WISHLIST BY CUSTOMER ID
// ------------------------------------------------------
export function addRemoveWishlist(id, token, fileName) {
  return async (dispatch) => {
    try {
      const options = {
        method: "POST",
        url: BASE_URL + "/wishlist/likeUnlikeWishlist",
        data: {
          id: id,
          fileName: fileName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getMessage(response.data.message));
      dispatch(wishlistItems(token));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
