import ProductItem from "components/ProductItem";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getProductByBrand } from "state/slices/beautyMall";
import { useDispatch, useSelector } from "state/store";

const BrandPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { name } = useParams();
  const { productsByBrand } = useSelector((state) => state.beautyMall);

  useEffect(() => {
    dispatch(getProductByBrand(location.state.id));
  }, [dispatch, location.state.id]);

  return (
    <>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <h2 className="page-header-title">{name}</h2>
              </div>
            </div>
            <div className="col-md-7">
              <h5 className="showing-pagination-results mt-5 mt-md-9 text-center text-md-end">
                {/* Showing {products.length} Results */}
                Showing {productsByBrand?.length} Results
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Product Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="row mb-n4 mb-sm-n10 g-3 g-sm-6">
            {productsByBrand?.map((item) => {
              return <ProductItem key={item._id} item={item} />;
            })}
          </div>
        </div>
      </section>
      {/*== End Product Area Wrapper ==*/}
    </>
  );
};

export default BrandPage;
