import React from "react";

const CancellationPolicy = () => {
  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item">
                    <a className="text-dark" href="/home">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active text-dark"
                    aria-current="page"
                  >
                    Cancellation Policy
                  </li>
                </ol>
                <h2 className="page-header-title">Cancellation Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Account Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <p>
            <b>Cancellation:</b>
          </p>
          <p>
            We generally do not accept any return or exchange for any delivered
            product unless it is broken or damaged. User can cancel their order
            before it start delivering the order.
          </p>
          <p>
            If your order is eligible for a refund and if you are approved, your
            refund will be processed, and a credit will automatically be applied
            to your credit card or original payment method within 15 days. The
            shipping fee and the amount paid for your canceled order are
            refunded.
          </p>
          <p>
            If you haven’t received a refund, first recheck your bank account.
            Then contact your credit card company; it may take some time before
            your refund is officially posted.
          </p>
          <p>
            Next, contact your bank. There is often some processing time before
            a refund is posted. If you’ve done all of this and still have not
            received your refund, don't hesitate to contact us at
            info@tbm.com.bd
          </p>
        </div>
      </section>
      {/*== End Account Area Wrapper ==*/}
    </div>
  );
};

export default CancellationPolicy;
