import About from "pages/about";
import Account from "pages/account";
import AllProducts from "pages/allProducts";
import BestSellers from "pages/bestSellers";
import BrandPage from "pages/brand";
import CancellationPolicy from "pages/cancellation";
import Cart from "pages/cart";
import CategoryPage from "pages/category";
import Contact from "pages/contact";
import Customer from "pages/customer";
import ProductDetails from "pages/details";
import Home from "pages/home";
import Invoice from "pages/invoice";
import Layout from "pages/layout";
import PaymentFailed from "pages/payementFailed";
import PaymentConfirm from "pages/paymentConfirm";
import Privacy from "pages/privacy";
import ReturnPolicy from "pages/return";
import Sale from "pages/sale";
import Terms from "pages/terms/Terms";
import WhatsNew from "pages/whatsNew";
import Wishlist from "pages/wishlist";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<Home />} />
            <Route path="/account" element={<Account />} />
            <Route path="/about" element={<About />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/shop" element={<AllProducts />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/customer" element={<Customer />} />
            <Route path="/allProducts" element={<AllProducts />} />
            <Route path="/invoice/:position" element={<Invoice />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/return" element={<ReturnPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/cancellation" element={<CancellationPolicy />} />
            <Route path="/what'snew" element={<WhatsNew />} />
            <Route path="/category/:name" element={<CategoryPage />} />
            <Route path="/brand/:name" element={<BrandPage />} />
            <Route path="/bestsellers" element={<BestSellers />} />
            <Route path="/sale" element={<Sale />} />
            <Route path="/paymentConfirmation" element={<PaymentConfirm />} />
            <Route path="/paymentFailed" element={<PaymentFailed />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
