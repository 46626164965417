import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetCart } from "state/slices/beautyMall";
import { useDispatch } from "state/store";

const PaymentConfirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCart());
  }, [dispatch]);

  return (
    <div>
      <section className="section-space">
        <div className="container">
          <div
            style={{
              background: "white",
              padding: 60,
              borderRadius: 4,
              display: "inline-block",
              margin: "auto",
            }}
          >
            <div
              style={{
                borderRadius: 200,
                height: 200,
                width: 200,
                background: "#F8FAF5",
                margin: "auto",
              }}
            >
              <i
                style={{
                  color: "#9ABC66",
                  fontSize: "100px",
                  lineHeight: "200px",
                  marginLeft: 50,
                }}
              >
                ✓
              </i>
            </div>
            <h1
              style={{
                color: "#88B04B",
                marginTop: 20,
                marginLeft: 50,
              }}
            >
              Success
            </h1>
            <p
              style={{
                color: "#404F5E",
                marginTop: 20,
                marginLeft: 40,
              }}
            >
              We received your purchase request;
              <br /> we'll be in touch shortly!
            </p>
          </div>
          <div
            style={{
              marginLeft: 100,
            }}
          >
            <button
              style={{
                background: "#ff6565",
                border: "#ff6565",
                color: "white",
                height: 50,
                width: 200,
                fontSize: 16,
                fontWeight: "bold",
              }}
              type="button"
              className="checkout-button"
              onClick={() => {
                navigate("/customer");
              }}
            >
              Go to Orders
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentConfirm;
