import React from "react";
import { deleteCart } from "state/slices/beautyMall";
import { useDispatch } from "state/store";
import { IMAGE_BASE_URL } from "utils/constants";

const SideCartItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleDeleteCart = (product) => {
    dispatch(deleteCart(product));
  };

  let colorItem;
  if (Array.isArray(item.variants)) {
    colorItem = item.variants[0].colors.find((element) => {
      return element._id === item.variant.value;
    });
  }

  return (
    <li className="aside-product-list-item">
      <div
        className="remove"
        type="button"
        onClick={() => {
          handleDeleteCart(item);
        }}
      >
        ×
      </div>
      <a href="product-details.html">
        {item?.variants[0]?.colors.length > 0 ? (
          <img
            src={`${IMAGE_BASE_URL}${colorItem?.media[0]?.fileName}`}
            width={68}
            height={84}
            alt="cart2"
          />
        ) : (
          <img
            src={`${IMAGE_BASE_URL}${item?.variants[0]?.media[0]?.fileName}`}
            width={68}
            height={84}
            alt="cart2"
          />
        )}
        <span className="product-title">
          {item.name} ({colorItem?.colorTitle})
        </span>
      </a>
      <span className="product-price">
        {item.quantity} × ৳{item.discountedPrice}
      </span>
    </li>
  );
};

export default SideCartItem;
