import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "state/store";

const Footer = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.customer);

  return (
    <div>
      {/*== Start Footer Area Wrapper ==*/}
      <footer className="footer-area">
        {/*== Start Footer Main ==*/}
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="widget-item">
                  <div className="widget-about">
                    <a className="widget-logo" href="/">
                      <img
                        src="/assets/images/tbm.png"
                        width={220}
                        height={68}
                        alt="Logo"
                      />
                    </a>
                    <p className="desc">
                      The Beauty Mall is one of the leading makeup stores in
                      Dhaka providing authentic products online and in store. We
                      have two showrooms. One is at Banani & the other is at
                      Baily Road.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 mt-md-0 mt-9">
                <div className="widget-item">
                  <h4 className="widget-title">Information</h4>
                  <ul className="widget-nav">
                    <li>
                      <a href="blog.html">Blog</a>
                    </li>
                    <li>
                      <a
                        href
                        onClick={() => {
                          navigate("/about");
                        }}
                      >
                        About us
                      </a>
                    </li>
                    <li>
                      <a
                        href
                        onClick={() => {
                          navigate("/contact");
                        }}
                      >
                        Contact
                      </a>
                    </li>
                    <li>
                      <a
                        href
                        onClick={() => {
                          token === null
                            ? navigate(`/account`)
                            : navigate(`/customer`);
                        }}
                      >
                        My Account
                      </a>
                    </li>
                    <li>
                      <a
                        href
                        onClick={() => {
                          token === null
                            ? navigate(`/account`)
                            : navigate(`/customer`);
                        }}
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        href
                        onClick={() => {
                          navigate("/shop");
                        }}
                      >
                        Shop
                      </a>
                    </li>
                    <li>
                      <a
                        href="/privacy"
                        onClick={() => {
                          navigate("/privacy");
                        }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/return"
                        onClick={() => {
                          navigate("/return");
                        }}
                      >
                        Return & Refund
                      </a>
                    </li>
                    <li>
                      <a
                        href="/cancellation"
                        onClick={() => {
                          navigate("/cancellation");
                        }}
                      >
                        Cancellation Policy
                      </a>
                    </li>
                    <li>
                      <a href="faq.html">FAQs</a>
                    </li>
                    <li>
                      <a
                        href="/terms"
                        onClick={() => {
                          navigate("/terms");
                        }}
                      >
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mt-lg-0 mt-6">
                <div className="widget-item">
                  <h4 className="widget-title">Social Info</h4>
                  <div className="widget-social">
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      <i className="fa fa-twitter" />
                    </a>
                    <a
                      href="https://www.facebook.com/thebeautymallbd"
                      target="_blank"
                      rel="noopener"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                    <a
                      href="https://www.pinterest.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      <i className="fa fa-pinterest-p" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*== End Footer Main ==*/}
        {/*== Start Footer Bottom ==*/}
        <div className="footer-bottom">
          <div className="container pt-0 pb-0">
            <div className="footer-bottom-content">
              <p className="copyright">
                © 2023 Beauty Mall. Made with <i className="fa fa-heart" /> by{" "}
                <a target="_blank" href="https://maxobyte.com">
                  Maxobyte.
                </a>
              </p>
            </div>
          </div>
        </div>
        {/*== End Footer Bottom ==*/}
      </footer>
      {/*== End Footer Area Wrapper ==*/}
    </div>
  );
};

export default Footer;
