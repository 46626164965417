import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getOrders, setLogout, setProfile } from 'state/slices/customer';
import { useDispatch, useSelector } from 'state/store';
import { BASE_URL, IMAGE_BASE_URL } from 'utils/constants';
import { FormattedDate } from 'utils/utils';
import axios from "utils/axios";

const Customer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myProfile } = useSelector((state) => state.customer);
  const { orders } = useSelector((state) => state.customer);
  const { token } = useSelector((state) => state.customer);
  const [firstName, setFirstName] = useState(myProfile.firstName);
  const [lastName, setLastName] = useState(myProfile.lastName);
  const [displayName, setDisplayName] = useState(myProfile.displayName);
  const [email, setEmail] = useState(myProfile.email);
  const [address, setAddress] = useState(myProfile.address);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reTypePassword, setReTypePassword] = useState("");
  const [image, setImage] = useState(myProfile?.photo ? IMAGE_BASE_URL + myProfile?.photo : "");

  const handleLogout = async () => {
    dispatch(setLogout());
    navigate("/account");
  }

  const getCustomerOrders = () => {
    dispatch(getOrders(myProfile.id, token));
  }

  const updateSubmit = async () => {
    var data = new FormData();
    data.append('firstName', firstName);
    data.append('lastName', lastName);
    data.append('displayName', displayName);
    data.append('email', email);
    console.log(image);
    if (image !== '') {
      data.append('photo', image);
    }
    try {
      const options = {
        method: "PATCH",
        url: BASE_URL + "/customer/editCustomer",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(options);
      dispatch(setProfile(response.data));
      alert(response.data.message)
    } catch (error) {
      alert(error)
    }
  }

  const updateAddress = async () => {
    var data = new FormData();
    data.append('address', address);
    try {
      const options = {
        method: "PATCH",
        url: BASE_URL + "/customer/editCustomer",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(options);
      dispatch(setProfile(response.data));
      alert(response.data.message)
    } catch (error) {
      alert(error)
    }
  }
  
  const updatePassword = async (values) => {
    try {
      const options = {
        method: "PATCH",
        url: BASE_URL + "/auth/changeCustomerPassword",
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(options);
      if (response.data.status) {
        alert('Your password has been changed.');
        setCurrentPassword("");
        setNewPassword("");
        setReTypePassword("");
      } else {
        alert(response.data.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePassword = async () => {
    if (currentPassword === '') {
      alert('Please Enter Current Password.')
      return;
    } else if (newPassword === '') { 
      alert('Please Enter New Password.')
      return;
    } else if (reTypePassword === '') { 
      alert('Please Enter Confirm Password.')
      return;
    }
    if (newPassword === reTypePassword) {
      const values = {
        oldPassword: currentPassword,
        newPassword: newPassword,
      }
      await updatePassword(values)
    } else {
      alert('New Password and Confirm Password are not matched.')
    }
  };

  function handleImage(e) {
    console.log(e.target.files[0]);
    setImage(e.target.files[0])
  }

  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section 
      className="page-header-area pt-10 pb-9"
      style={{
        backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item"><a className="text-dark" href="/home">Home</a></li>
                  <li className="breadcrumb-item active text-dark" aria-current="page">My Account</li>
                </ol>
                <h2 className="page-header-title">My Account</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start My Account Area Wrapper ==*/}
      <section className="my-account-area section-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="my-account-tab-menu nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="dashboad-tab" data-bs-toggle="tab" data-bs-target="#dashboad" type="button" role="tab" aria-controls="dashboad" aria-selected="true">Dashboard</button>
                <button className="nav-link" id="orders-tab" data-bs-toggle="tab" data-bs-target="#orders" type="button" role="tab" aria-controls="orders" aria-selected="false" onClick={
                  () => {getCustomerOrders()}
                }> Orders</button>
                <button className="nav-link" type="button" onClick={
                  () => {navigate('/wishlist');}
                }> Wishlist</button>
                <button className="nav-link" id="payment-method-tab" data-bs-toggle="tab" data-bs-target="#payment-method" type="button" role="tab" aria-controls="payment-method" aria-selected="false">Change Password</button>
                <button className="nav-link" id="address-edit-tab" data-bs-toggle="tab" data-bs-target="#address-edit" type="button" role="tab" aria-controls="address-edit" aria-selected="false">address</button>
                <button className="nav-link" id="account-info-tab" data-bs-toggle="tab" data-bs-target="#account-info" type="button" role="tab" aria-controls="account-info" aria-selected="false">Account Details</button>
                <button className="nav-link" onClick={() => {
                  handleLogout();
                }} type="button">Logout</button>
              </div>
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="dashboad" role="tabpanel" aria-labelledby="dashboad-tab">
                  <div className="myaccount-content">
                    <h3>Dashboard</h3>
                    <div className="welcome">
                      <p>Hello, <strong>{myProfile?.displayName}</strong> (If Not <strong>{myProfile?.firstName} !</strong><a href="account-login.html" className="logout"> Logout</a>)</p>
                    </div>
                    <p>From your account dashboard. you can easily check &amp; view your recent orders, manage your shipping and billing addresses and edit your password and account details.</p>
                  </div>
                </div>
                <div className="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                  <div className="myaccount-content">
                    <h3>Orders</h3>
                    <div className="myaccount-table table-responsive text-center">
                      <table className="table table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th>Order</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          { orders.map((item, index) => {
                            return (
                              <tr>
                              <td>{index + 1}</td>
                              <td>Aug 22, 2018</td>
                              <td>{item.status}</td>
                              <FormattedDate createdDate={item.createdAt} />
                              <td><a href={`/invoice/${index}`} className="check-btn sqr-btn ">View</a></td>
                            </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="download" role="tabpanel" aria-labelledby="download-tab">
                  <div className="myaccount-content">
                    <h3>Downloads</h3>
                    <div className="myaccount-table table-responsive text-center">
                      <table className="table table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th>Product</th>
                            <th>Date</th>
                            <th>Expire</th>
                            <th>Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Haven - Free Real Estate PSD Template</td>
                            <td>Aug 22, 2018</td>
                            <td>Yes</td>
                            <td><a href="#/" className="check-btn sqr-btn"><i className="fa fa-cloud-download" /> Download File</a></td>
                          </tr>
                          <tr>
                            <td>HasTech - Profolio Business Template</td>
                            <td>Sep 12, 2018</td>
                            <td>Never</td>
                            <td><a href="#/" className="check-btn sqr-btn"><i className="fa fa-cloud-download" /> Download File</a></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="payment-method" role="tabpanel" aria-labelledby="payment-method-tab">
                  <div className="myaccount-content">
                    <h3>Password change</h3>
                    <div className='account-details-form'>
                      <form>
                        <fieldset>
                          <div className="single-input-item">
                            <label htmlFor="current-pwd" className="required">Current Password</label>
                            <input
                              type="password"
                              value={currentPassword}
                              onChange={(e) => setCurrentPassword(e.target.value)}
                              />
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="single-input-item">
                                <label htmlFor="new-pwd" className="required">New Password</label>
                                <input
                                  type="password"
                                  value={newPassword}
                                  onChange={(e) => setNewPassword(e.target.value)}
                                  />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="single-input-item">
                                <label htmlFor="confirm-pwd" className="required">Confirm Password</label>
                                <input
                                  type="password"
                                  value={reTypePassword}
                                  onChange={(e) => setReTypePassword(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="single-input-item">
                          <button className="check-btn sqr-btn"
                            type="button"
                            onClick={() => {handleChangePassword()}}
                          >Update Password</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="address-edit" role="tabpanel" aria-labelledby="address-edit-tab">
                  <div className="myaccount-content">
                    <h3>Address</h3>
                    <address>
                      <div className="account-details-form">
                        <div className="single-input-item">
                          <label htmlFor="display-name" className="required">Shipping Address</label>
                          <input 
                          type="text" 
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                        <div className="single-input-item">
                          <button className="check-btn sqr-btn"
                            type="button"
                            onClick={() => {
                              updateAddress()
                            }}
                          >Update Address</button>
                        </div>
                      </div>
                    </address>
                  </div>
                </div>
                <div className="tab-pane fade" id="account-info" role="tabpanel" aria-labelledby="account-info-tab">
                  <div className="myaccount-content">
                    <h3>Account Details</h3>
                    <div className="account-details-form">
                      <form>
                        <div>
                          <img src={image === "" ? '/assets/images/photos/user.jpg'
                            : typeof(image) === 'string' && image.includes(IMAGE_BASE_URL) ? image : URL.createObjectURL(image)
                          } alt="1" style={{
                            height: "250px",
                            width: "250px",
                            marginBottom: "20px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }} />
                        </div>
                        <div style={{
                          marginBottom: "20px",
                        }}>
                          <input type="file" name='file' onChange={handleImage} style={{
                            color: 'white',
                            display: 'inline-block',
                            background: '#FF6565',
                            border: 'none',
                            padding: '7px 15px',
                            fontWeight: '700',
                            borderRadius: '5px',
                            whiteSpace: 'nowrap',
                            cursor: 'pointer',
                            fontSize: '10pt',
                          }} />
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="single-input-item">
                              <label htmlFor="first-name" className="required">First Name</label>
                              <input 
                              type="text" 
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="single-input-item">
                              <label htmlFor="last-name" className="required">Last Name</label>
                              <input 
                              type="text" 
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="single-input-item">
                          <label htmlFor="display-name" className="required">Display Name</label>
                          <input 
                          type="text" 
                          value={displayName}
                          onChange={(e) => setDisplayName(e.target.value)}
                          />
                        </div>
                        <div className="single-input-item">
                          <label htmlFor="email" className="required">Email Addres</label>
                          <input 
                          type="email" 
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="single-input-item">
                          <label htmlFor="phone" className="required">Phone</label>
                          <input className='pointer-events: none'
                          type="text" 
                          value={myProfile.phone} />
                        </div>
                        <div className="single-input-item">
                          <button className="check-btn sqr-btn"
                            type="button"
                            onClick={() => {
                              updateSubmit()
                            }}
                          >Save Changes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End My Account Area Wrapper ==*/}

    </div>
  )
}

export default Customer;