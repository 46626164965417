import React, { useEffect } from "react";
import { addRemoveWishlist } from "state/slices/beautyMall";
import { wishlistItems } from "state/slices/customer";
import { useDispatch, useSelector } from "state/store";
import { IMAGE_BASE_URL } from "utils/constants";

const Wishlist = () => {
  const dispatch = useDispatch();
  const { wishlists } = useSelector((state) => state.customer);
  const { token } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(wishlistItems(token));
  }, [dispatch, token]);

  function updateWishlist(id) {
    if (token) {
      dispatch(addRemoveWishlist(id, token, ""));
    } else {
      alert("Please login to save wishlist");
    }
  }

  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <nav aria-label="breadcrumb" className="breadcrumb-style1">
        <div className="container">
          <ol className="breadcrumb justify-content-center">
            <li className="breadcrumb-item">
              <a href="/home">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Wishlist
            </li>
          </ol>
        </div>
      </nav>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Wishlist Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <div className="shopping-wishlist-form table-responsive">
            <form action="#" method="post">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th className="product-remove">&nbsp;</th>
                    <th className="product-thumbnail">&nbsp;</th>
                    <th className="product-name">Product name</th>
                    <th className="product-price">Unit price</th>
                    <th className="product-stock-status">Stock status</th>
                  </tr>
                </thead>
                <tbody>
                  {wishlists.map((item) => {
                    return (
                      <tr className="tbody-item">
                        <td className="product-remove">
                          <div
                            type="button"
                            className="remove"
                            onClick={() => {
                              updateWishlist(item.product.id);
                            }}
                          >
                            ×
                          </div>
                        </td>
                        <td className="product-thumbnail">
                          <div className="thumb">
                            <a href="single-product.html">
                              <img
                                src={IMAGE_BASE_URL + item.fileName}
                                width={68}
                                height={84}
                                alt="pp"
                              />
                            </a>
                          </div>
                        </td>
                        <td className="product-name">
                          <a
                            className="title"
                            href={`/product/${item.product.id}`}
                          >
                            {item.product.name}
                          </a>
                        </td>
                        <td className="product-price">
                          <span className="price">
                            ৳{item.product.discountedPrice}
                          </span>
                        </td>
                        <td className="product-stock-status">
                          <span className="wishlist-in-stock">In Stock</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </section>
      {/*== End Wishlist Area Wrapper ==*/}
    </div>
  );
};

export default Wishlist;
