import {
  decreaseQuantity,
  deleteCart,
  increaseQuantity,
} from "state/slices/beautyMall";
import { useDispatch } from "state/store";
import { IMAGE_BASE_URL } from "utils/constants";

const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleDeleteCart = (product) => {
    dispatch(deleteCart(product));
  };

  const handleIncreaseQuantity = (productId) => {
    dispatch(increaseQuantity(productId));
  };

  const handleDecreaseQuantity = (productId) => {
    dispatch(decreaseQuantity(productId));
  };

  let colorItem;
  if (Array.isArray(item.variants)) {
    colorItem = item.variants[0].colors.find((element) => {
      return element._id === item.variant.value;
    });
  }

  return (
    <tr className="tbody-item">
      <td className="product-remove">
        <div
          type="button"
          className="remove"
          onClick={() => {
            handleDeleteCart(item);
          }}
        >
          ×
        </div>
      </td>
      <td className="product-thumbnail">
        <div className="thumb">
          <a href={`/product/${item._id}`}>
            {item?.variants[0]?.colors.length > 0 ? (
              <img
                src={`${IMAGE_BASE_URL}${colorItem?.media[0]?.fileName}`}
                width={68}
                height={84}
                alt="cart2"
              />
            ) : (
              <img
                src={`${IMAGE_BASE_URL}${item?.variants[0]?.media[0]?.fileName}`}
                width={68}
                height={84}
                alt="cart2"
              />
            )}
          </a>
        </div>
      </td>
      <td className="product-name">
        <a className="title" href={`/product/${item._id}`}>
          {item.name} ({colorItem?.colorTitle})
        </a>
      </td>
      <td className="product-price">
        <span className="price">৳{item.discountedPrice}</span>
      </td>
      <td className="product-quantity">
        <div className="pro-qty">
          <input
            type="text"
            className="quantity"
            title="Quantity"
            defaultValue={1}
            value={item.quantity}
          />
          <div
            className="dec qty-btn"
            onClick={() => {
              if (item.quantity > 1) {
                handleDecreaseQuantity(item._id);
              }
            }}
          >
            -
          </div>
          <div
            className="inc qty-btn"
            onClick={() => {
              handleIncreaseQuantity(item._id);
            }}
          >
            +
          </div>
        </div>
      </td>
      <td className="product-subtotal">
        <span className="price">৳{item.subTotal}</span>
      </td>
    </tr>
  );
};

export default CartItem;
