import React from "react";

const Privacy = () => {
  return (
    <div>
      {/*== Start Page Header Area Wrapper ==*/}
      <section
        className="page-header-area pt-10 pb-9"
        style={{
          backgroundColor: "#FFF3DA",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="page-header-st3-content text-center text-md-start">
                <ol className="breadcrumb justify-content-center justify-content-md-start">
                  <li className="breadcrumb-item">
                    <a className="text-dark" href="/home">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active text-dark"
                    aria-current="page"
                  >
                    Privacy Policy
                  </li>
                </ol>
                <h2 className="page-header-title">Privacy Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*== End Page Header Area Wrapper ==*/}

      {/*== Start Account Area Wrapper ==*/}
      <section className="section-space">
        <div className="container">
          <p className="desc">
            At The Beauty Mall, we are committed to protecting your personal
            information and respecting your privacy. It is your data, and we
            respect that. This Privacy Policy tells you how and why we collect
            and use the personal data you provide or collect about you when you
            interact with us, for example, when you use our Website or visit our
            store.
          </p>
          <p className="desc">
            We want you to know how we use your data, keep it secure, and your
            rights. We will only keep your information for as long as we are
            either required to by law or as is relevant for the purposes for
            which it was collected.
          </p>
          <p>
            You can visit the Website and browse without having to provide
            personal details. During your visit to the Website you remain
            anonymous, and we cannot identify you unless you have an account on
            the Website and log on with your username and password.
          </p>
          <p>
            We trust this Privacy Policy will answer any questions you have. But
            if not, please contact us directly at info@tbm.com.bd or use the
            contact details provided at the end of this Privacy Policy. We will
            likely need to update this Privacy Policy occasionally by updating
            this page. We will notify you of any significant changes, but we
            encourage you to come back and review it from time to time.
          </p>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Ensuring the Lawful Use of Your Data
          </p>
          <p>
            We will only use your data where we have a lawful basis to use it.
            We will only use your data where:
          </p>
          <p>
            For example, we must perform our contract with you (for example, to
            fulfill your order). It is expected to be part of running our
            business.
          </p>
          <p>
            It does not adversely impact your interests, rights, or freedoms.
          </p>
          <p>
            For example, we might use your purchase history to send you
            personalized offers. We might also combine your shopping history to
            identify trends and improve our ability to keep up with demand and
            introduce the right new products for our customers. Don't hesitate
            to contact us using the contact details provided at the end of this
            Privacy Policy if you want further information.
          </p>
          <p>
            We may need to use data to comply with our legal obligations. One
            example is to provide details related to fraud to law enforcement
            agencies. In other instances, we will ask for your consent to use
            your data, for example, where you sign-up to receive our email
            newsletters. Further details regarding our use of your personal
            information are provided below.
          </p>
          <p>
            <b>
              SECTION 1: WHAT INFORMATION WE COLLECT FROM YOU AND HOW WE USE IT
            </b>
          </p>
          <p>
            The information we collect about you and how we use it depends on
            how you interact with us. For example, you might order on our
            Website, contact us with a question by email or phone, make a
            purchase, or book an appointment in one of the stores that carry our
            products. The information discussed below provides examples of the
            information we collect about you and how we will use it.
          </p>
          <p>
            We will collect personal data needed to identify you, such as your
            name, username, password, and date of birth. We will also collect
            your contact details, including your email address, telephone
            number, and billing/delivery address. We will use your information
            as detailed below.
          </p>
          <p>
            To fulfill our contract with you: To fulfill your order, for
            example, by delivering your products or contacting you about your
            order where necessary.
          </p>
          <p>
            Legitimate business purposes: To allow you to create an account with
            us.
          </p>
          <p>
            To communicate your order or booking with you, if you have asked a
            question or sent us a complaint.
          </p>
          <p>
            To allow you to complete any surveys we send you (if you wish to) or
            to comment on or review our products or services to help us improve
            them.
          </p>
          <p>
            <b>To support fraud prevention and detection</b>
          </p>
          <p>
            Where you consent: To send you email newsletters to keep you
            up-to-date about our products, services, and latest offers, which we
            think will interest you (only where you consent).
          </p>
          <p>
            Legitimate business purposes: To provide you with the support and
            customer service you have requested.
          </p>
          <p>
            We may track technical information about your equipment, browsing
            actions, and patterns. We collect this personal data using cookies,
            server logs, and similar technologies. We provide details regarding
            how we process and use cookies, which can be found in our Cookies
            Policy. We will use this information as detailed below.
          </p>
          <p>
            Legitimate business purposes: To administer and to improve our
            Website, to ensure it is presented in the Most effective manner for
            you and to give you the best Website experience, and to allow you to
            participate in interactive features of our Website if you choose to
            do so For data analysis, testing, research, and statistical
            statistics to help us to improve our products and services.
          </p>
          <p>To keep our Website safe and secure</p>
          <p>
            To make suggestions and recommendations to you and other users of
            our Website about products or services that may interest you or them
          </p>
          <p>
            To measure or understand the effectiveness of advertising, we direct
            to you and others and deliver relevant advertising to you.
          </p>
          <p>
            We may gather additional information you choose to provide,
            including your hair color, eye color, and skin tone. We will use
            this information as detailed below.
          </p>
          <p>
            Legitimate business purposes: To assist us in providing you with a
            more personalized beauty experience You do not have to give us any
            of the personal data discussed above. But, if you do not provide us
            with certain information, we may be unable to ship you the goods and
            deliver the requested services.
          </p>
          <p>
            The forms you fill in on our Website will clarify what information
            we need to provide the product or service you are requesting and
            what information you can decide to provide. To help us better
            understand you as a customer, we combine your personal data, such as
            your shopping history.
          </p>
          <p>
            <b>SECTION 2: SHARING YOUR DATA WITH THIRD PARTIES</b>
          </p>
          <p>
            The third-party providers we use will only collect, use and disclose
            your information to the extent necessary to allow them to perform
            the services they provide us.
          </p>
          <p>
            However, certain third-party service providers, such as payment
            gateways and other payment transaction processors, have their own
            privacy policies regarding the information we are required to
            provide them for your purchase-related transactions.
          </p>
          <p>
            For these providers, we recommend that you read their privacy
            policies to understand how they will handle your personal
            information.
          </p>
          <p>
            In particular, remember that certain providers may be located in or
            have facilities in a different jurisdiction than you or us. So if
            you elect to proceed with a transaction involving a third-party
            service provider's services, your information may become subject to
            the laws of the jurisdiction(s) in which that service provider or
            its facilities are located.
          </p>
          <p>
            For example, if you are located in Canada, and your transaction is
            processed by a payment gateway in the United States. Your personal
            information in completing that transaction may be subject to
            disclosure under United States legislation, including the Patriot
            Act.
          </p>
          <p>
            Once you leave our store's Website or are redirected to a
            third-party website or application, you are no longer governed by
            this Privacy Policy or our Website's Terms of Service.
          </p>
          <p>
            <b>SECTION 3: DISCLOSURE</b>
          </p>
          <p>
            We may be required by law to share your data with the police,
            administrative authorities, or other enforcement, regulatory, or
            Government bodies or if you violate our Terms of Service.
          </p>
          <p>
            <b>SECTION 4: COOKIES</b>
          </p>
          <p>
            The acceptance of cookies is not a requirement for visiting the
            Website. However, using the 'basket' functionality on the Site and
            ordering is only possible with the activation of cookies. Cookies
            are tiny text files that identify your computer to our server as a
            unique user when you visit certain pages on the Site, and your
            Internet browser stores them on your computer's hard drive. Cookies
            can recognize your Internet Protocol address, saving you time while
            you are on or want to enter the Site. We only use cookies for your
            convenience in using the Site (for example, to remember who you are
            when you want to amend your shopping cart without re-enter your
            email address) and not for obtaining or using any other information
            about you (for example, targeted advertising). Your browser can be
            set not to accept cookies, but this would restrict your use of the
            Site. Please be assured that our use of cookies contains no personal
            or private details and is virus-free. If you want more information
            about cookies, go to{" "}
            <a href="http://www.allaboutcookies.org">
              http://www.allaboutcookies.org
            </a>
            , or to remove them from your browser, go to
            <a href="http://www.allaboutcookies.org/manage-cookies/index.html">
              http://www.allaboutcookies.org/manage-cookies/index.html
            </a>
          </p>
          <p>
            This Website uses Google Analytics, a web analytics service that
            Google, Inc. ("Google") provides. Google Analytics uses cookies and
            text files placed on your computer to help the Website analyze how
            users use the Site. The information generated by the cookie about
            your use of the Website (including your IP address) will be
            transmitted to and stored by Google on servers in the United States.
            Google will use this information to evaluate your use of the
            Website, compile reports on website activity for website operators
            and provide other services relating to website activity and internet
            usage. Google may also transfer this information to third parties
            where required by law or where such third parties process the
            information on Google's behalf. Google will not associate your IP
            address with any other data held by Google. You may refuse the use
            of cookies by selecting the appropriate settings on your browser;
            however, please note that if you do this, you may not be able to use
            the full functionality of this Website. By using this Website, you
            consent to processing data about you by Google in the manner and for
            the purposes set out above.
          </p>
          <p>
            <b>SECTION 5: PAYMENT</b>
          </p>
          <p>
            Suppose you choose a direct payment gateway to complete your
            purchase. In that case, Payment Gateway stores your credit card data
            only as long as it is necessary to complete your purchase
            transaction. It is encrypted through the Payment Card Industry Data
            Security Standard (PCI-DSS). Your purchase transaction data is
            stored only as long as necessary to complete your purchase
            transaction. After that is complete, your purchase transaction
            information is deleted.
          </p>
          <p>
            All direct payment gateways adhere to the standards set by PCI-DSS
            as managed by the PCI Security Standards Council, which is a joint
            effort of brands like Visa, MasterCard, American Express, and
            Discover.
          </p>
          <p>
            PCI-DSS requirements help ensure our store and its service
            providers' secure handling of credit card information.
          </p>
          <p>
            <b>SECTION 6: Security</b>
          </p>
          <p>
            We have appropriate technical and security measures to prevent
            unauthorized or unlawful access to or accidental loss of or
            destruction, or damage to your information. When we collect data
            through the Site, we collect your details on a secure server. We use
            firewalls on our servers. Our security procedures mean we may
            occasionally request proof of identity before disclosing personal
            information. You are responsible for protecting against unauthorized
            access to your password and computer.
          </p>
          <p>
            <b>SECTION 7: YOUR RIGHTS</b>
          </p>
          <p>
            You have the following rights regarding the personal information we
            hold about you to request: Access to the personal data we hold about
            you (commonly known as a "data subject access request"), including a
            copy of it.
          </p>
          <p>
            The correction of the personal information that we hold about you if
            it is not complete or accurate. But if you have an account with us,
            you can do this in some instances yourself by visiting the Account
            Information page on our Website.
          </p>
          <p>
            The deletion or removal of personal data we hold about you where
            there is no good reason for us continuing to process it or where you
            have exercised your right to object to processing (see below).
          </p>
          <p>
            For our processing of your personal information to be restricted to
            unavoidable circumstances, for example, if you want to establish its
            accuracy or the reason for processing it; and To obtain a copy of
            the personal information you provided and reuse it elsewhere or to
            ask us to transfer it to a third party of your choice.
          </p>
          <p>
            As a security measure to protect your data, we may ask you for proof
            of your identity before dealing with your request.
          </p>
          <p>
            Right to Object: If we are processing your data based on our
            legitimate interests, you can ask us to stop processing it. We must
            do so unless we have an overriding legitimate reason to continue
            processing your personal data.
          </p>
          <p>
            <b>SECTION 8: CONTACTING US</b>
          </p>
          <p>
            If you have any queries, comments, or requests regarding this
            Privacy Policy, you have a complaint, or you would like to exercise
            any of your rights set out above, you can contact us in the
            following ways:
          </p>
          <p>By email at info@tbm.com.bd. </p>
        </div>
      </section>
      {/*== End Account Area Wrapper ==*/}
    </div>
  );
};

export default Privacy;
